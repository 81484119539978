import { forwardRef } from 'react'

import { TextInput, TextInputProps } from '@mantine/core'

const ApplicationTextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const labelStyles = 'text-sm text-doubleNickel-gray-600 font-medium'
    const wrapperStyles = 'border-b-[1px] border-doubleNickel-gray-300'
    const inputStyles = `text-base font-medium`
    const rootStyles = `w-full`

    return (
      <TextInput
        ref={ref}
        variant={'unstyled'}
        classNames={{
          label: labelStyles,
          wrapper: wrapperStyles,
          input: inputStyles,
        }}
        className={rootStyles}
        {...props}
      />
    )
  }
)

export default ApplicationTextInput
