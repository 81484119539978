import { useState, useCallback } from 'react'

import { useLazyQuery } from '@apollo/client'
import { PasswordInput, TextInput } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import dayjs from 'dayjs'

import { navigate } from '@redwoodjs/router'
import { routes } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import DocumentPreview from 'src/components/Overlays/DocumentPreview/DocumentPreview'
import EmailSenderModal from 'src/components/Overlays/EmailSenderModal/EmailSenderModal'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_DOCUMENT_DOWNLOAD_URL } from 'src/graphql/documents.graphql'
import { VoEType } from 'src/graphql/types/verificationOfEmployments'
import {
  DELETE_VOE,
  GET_VOE_LIST,
  SEND_INSTRUCTIONS_EMAIL_FOR_OUTGOING_VOE,
} from 'src/graphql/voe.graphql'
import { downloadFile } from 'src/utils/downloadFile'

import UploadDocumentModal from '../DocumentsTab/components/UploadDocumentModal'

import ContactModal from './components/ContactModal/ContactModal'
import EditVoEModal from './components/EditVoEModal/EditVoEModal'
import VoEApplicationTable from './components/VoEApplicationTable/VoEApplicationTable'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const LIMIT = 100

const VoETab = ({ applicantId, applicant }) => {
  console.log('ok', applicant)
  const [isUploadDocumentModalOpen, setIsDocumentUploadModalOpen] =
    useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const [selectedVoe, setSelectedVoe] = useState(null)
  const [documentForPreview, setDocumentForPreview] = useState(null)
  const [openEmailModal, { open, close }] = useDisclosure(false)

  const { currentUser } = useAuth()

  const { data: voeList, loading } = useQuery(GET_VOE_LIST, {
    variables: {
      filters: {
        type: VoEType.OUTBOUND,
        applicantId,
      },
      pageInfo: { offset: 0, limit: LIMIT },
    },
    onError,
  })

  const refetchQueries = [
    {
      query: GET_VOE_LIST,
      variables: {
        filters: {
          type: VoEType.OUTBOUND,
          applicantId,
        },
        pageInfo: { offset: 0, limit: LIMIT },
      },
    },
  ]

  const [deleteVoe] = useMutation(DELETE_VOE, {
    refetchQueries,
    onCompleted: () => {
      toast('VoE deleted successfully', 'success')
    },
    onError: () => {
      toast('Error deleting VoE', 'error')
    },
  })

  const [downloadDocument, { called, data: downloadResponse }] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_URL,
    {
      onCompleted: () => {
        if (called && downloadResponse?.documentDownloadUrl?.url) {
          const { url, fileName } = downloadResponse.documentDownloadUrl
          downloadFile({ url, fileName })
        }
      },
      onError,
    }
  )

  const [sendInstructionsEmailForOutgoingVoE, { loading: loadingSendEmail }] =
    useMutation(SEND_INSTRUCTIONS_EMAIL_FOR_OUTGOING_VOE, {
      refetchQueries,
      onCompleted: () => {
        toast('Email sent', 'success')
        close()
      },
      onError: (error) => {
        toast(error.message, 'error')
      },
    })

  const handleDownload = (documentId) => {
    if (documentId) {
      downloadDocument({
        variables: {
          id: documentId,
          applicantId,
        },
      })
    }
  }

  const handlePreview = (document) => {
    setDocumentForPreview(document)
  }

  const handleUpload = (voe) => {
    setSelectedVoe(voe)
    setIsDocumentUploadModalOpen(true)
  }

  const handleEdit = (voe) => {
    setSelectedVoe(voe)
    setIsEditModalOpen(true)
  }

  const handleContact = (voe) => {
    setSelectedVoe(voe)
    setIsContactModalOpen(true)
  }

  const handleEmailModal = (voe) => {
    setSelectedVoe(voe)
    open()
  }

  const handleDelete = (documentId) => {
    deleteVoe({
      variables: {
        id: documentId,
      },
    })
  }

  const onSendEmail = ({ email }) => {
    sendInstructionsEmailForOutgoingVoE({
      variables: {
        emailTo: email,
        voeId: selectedVoe.voeId,
      },
    })
  }

  const handleViewDetail = useCallback(
    (voe) => {
      navigate(
        routes.voeResponseDetails({
          id: applicantId,
          tab: 'voe',
          voeId: voe.voeId,
        })
      )
    },
    [applicantId]
  )
  const firstName = currentUser?.firstName
  const lastName = currentUser?.lastName
  const emailAddress = currentUser?.email
  const phoneNumber = currentUser?.phone
  const companyName = currentUser?.companyName

  // Constructing the recruiter's full name from given first and last names
  const recruiterFullName = `${firstName} ${lastName}`

  // Constructing the applicant's full name
  const applicantFirstName = applicant?.firstName
  const applicantLastName = applicant?.lastName
  const applicantFullName = `${applicantFirstName} ${applicantLastName}`
  const emailBody = `Hello,

I hope this email finds you well. My name is ${recruiterFullName}, and I am a recruiter at ${companyName}.

We are currently processing an application for ${applicantFullName}, who has listed you as a previous employer. This verification is necessary to comply with  FMCSA regulations, and we require your assistance to ensure all details are accurate.

To facilitate this process, please confirm the verification of employment using the following link. You can log in using your email address; a one-time password will be sent to you to access the information securely.

Attached is a PDF containing the details provided by ${applicantFullName}. Alternatively, you can complete the document and send the updated document back to ${emailAddress}.

We value your time and appreciate your prompt attention to this matter. If you have any questions or need further assistance, please don't hesitate to contact me at ${emailAddress} or call ${phoneNumber}

Thank you in advance for your cooperation and assistance.

Warm regards,
${recruiterFullName}
${companyName}`

  return (
    <div className="flex h-[90%] flex-col gap-4 overflow-auto">
      <div className="flex flex-row items-center gap-2">
        <div className="flex w-full flex-row items-center gap-2">
          <div className="text-lg font-semibold text-doubleNickel-black">
            Verification of Employment
          </div>
          <div className="ml-auto text-sm font-semibold text-doubleNickel-gray-700">
            Date of Birth:
          </div>
          <TextInput
            w={125}
            variant="unstyled"
            readOnly
            value={
              applicant?.application?.dob
                ? dayjs(applicant?.application?.dob).format('MM/DD/YYYY')
                : 'Not Available'
            }
          />
          <div className="text-sm font-semibold text-doubleNickel-gray-700">
            SSN:
          </div>
          {applicant?.application?.ssn ? (
            <PasswordInput
              w={125}
              variant="unstyled"
              value={applicant.application.ssn}
            />
          ) : (
            <TextInput
              w={125}
              variant="unstyled"
              readOnly
              value={'Not Available'}
            />
          )}
        </div>
      </div>
      {documentForPreview && (
        <DocumentPreview
          applicantId={applicantId}
          documentId={documentForPreview.documentId}
          onBack={() => setDocumentForPreview(null)}
          showActions={false}
        />
      )}
      {!documentForPreview && (
        <VoEApplicationTable
          voeList={voeList?.verificationOfEmployments.items}
          loading={loading}
          onEdit={handleEdit}
          onUpload={handleUpload}
          onContact={handleContact}
          onDownload={handleDownload}
          onPreview={handlePreview}
          onEmail={handleEmailModal}
          onViewDetail={handleViewDetail}
          onDelete={handleDelete}
        />
      )}

      <UploadDocumentModal
        step={null}
        applicantId={applicantId}
        isOpen={isUploadDocumentModalOpen}
        onRender={refetchQueries}
        onClose={() => setIsDocumentUploadModalOpen(false)}
        voe={selectedVoe}
        showExpirationDate={false}
        disableCategorySelection={true}
      />

      {isEditModalOpen && (
        <EditVoEModal
          voe={selectedVoe}
          isOpen={isEditModalOpen}
          onRender={refetchQueries}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}

      {isContactModalOpen && (
        <ContactModal
          voe={selectedVoe}
          isOpen={isContactModalOpen}
          onRender={refetchQueries}
          onClose={() => {
            setIsContactModalOpen(false)
          }}
        />
      )}

      {openEmailModal && (
        <EmailSenderModal
          from={currentUser?.email}
          to={selectedVoe?.email}
          title="Send instructions via Email"
          subject={`Employment verification request from ${companyName}`}
          body={emailBody}
          isOpen={openEmailModal}
          onClose={close}
          onSend={onSendEmail}
          loading={loadingSendEmail}
        />
      )}
    </div>
  )
}

export default VoETab
