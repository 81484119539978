import { useCallback, useEffect, useState } from 'react'

import { NetworkStatus } from '@apollo/client'
import { ActionIcon, Input, Loader, Modal } from '@mantine/core'
import { useInView } from 'react-intersection-observer'

import { navigate, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import {
  usePhoneDevice,
  usePhoneDeviceDispatch,
} from 'src/context/PhoneDeviceContext'
import { GET_APPLICANTS } from 'src/graphql/applicants.graphql'
import { StepStatus } from 'src/graphql/types/steps'
import IconMessage from 'src/icons/IconMessage'
import IconPhoneCall from 'src/icons/IconPhoneCall'
import { buildFullName, formatSnakeValue } from 'src/lib/formatters'
import { PhoneDeviceActionType } from 'src/lib/phone-device.reducer'
import {
  formatInternationalPhoneNumber,
  unformatPhoneNumber,
} from 'src/lib/phone.utils'

import { toast } from '../Toast/Toast'

const SearchModal = ({ opened, close }) => {
  const [search, setSearch] = useState('')
  const dispatchPhoneAction = usePhoneDeviceDispatch()

  const phoneDeviceContext = usePhoneDevice()
  const resultsPerPage = 10
  const page = 1

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const {
    data: applicantsData,
    loading,
    fetchMore,
    networkStatus,
  } = useQuery(GET_APPLICANTS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      pageInfo: {
        offset: (page - 1) * resultsPerPage,
        limit: resultsPerPage,
      },
      filters: {
        searchText: search,
      },
      stepFilters: {
        OR: [
          { status: StepStatus.IN_PROGRESS },
          { status: StepStatus.PENDING_REVIEW },
        ],
      },
    },
    onError: (error) => {
      console.log('error', error)
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const applicants = applicantsData?.applicants?.items || []

  useEffect(() => {
    if (inView) {
      fetchMore({
        variables: {
          pageInfo: {
            offset: applicants.length ?? 0,
            limit: resultsPerPage,
          },
        },
      })
    }
  }, [inView])

  const onPhoneCall = useCallback(
    async ({ applicantId, applicantFullName, phone }) => {
      if (phoneDeviceContext?.phoneDevice?.isBusy) {
        return
      }
      dispatchPhoneAction({
        type: PhoneDeviceActionType.CALL,
        payload: {
          applicantId,
          phone,
          applicantFullName,
        },
      })
    },
    []
  )

  const calculateBadeColor = (stage) => {
    switch (stage) {
      case 'PROSPECT':
        return 'gray'
      case 'HIRED':
        return 'green'
      case 'TERMINATED':
        return 'red'
      case 'DISQUALIFIED':
        return 'orange'
      case 'NOT_INTERESTED':
        return 'orange'
      default:
        return 'gray'
    }
  }

  return (
    <Modal
      opened={opened}
      onClose={close}
      yOffset={100}
      overlayProps={{
        backgroundOpacity: 0.2,
        blur: 5,
      }}
      size="50%"
      withCloseButton={false}
      classNames={{
        body: 'pt-4 px-0',
      }}
    >
      <Input
        variant="unstyled"
        placeholder="Search applicants"
        value={search}
        onChange={(event) => setSearch(event.currentTarget.value)}
        className="px-4"
        size="lg"
        rightSection={loading && <Loader size="xs" />}
      />

      {applicants.map((applicant) => (
        <a
          key={applicant.applicantId}
          className="group flex flex-row items-center gap-4 px-4 py-3 hover:bg-doubleNickel-gray-100"
          target="_blank"
          href={`/applicants/${applicant.applicantId}`}
          rel="noreferrer"
        >
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-2 text-base font-medium text-doubleNickel-gray-900">
              {buildFullName(applicant)}
              <StyledBadge
                color={calculateBadeColor(applicant.applicantStage)}
                textTransform="capitalize"
              >
                {applicant.applicantStage === 'PROSPECT'
                  ? formatSnakeValue(applicant.hiringStage)
                  : formatSnakeValue(applicant.applicantStage)}
              </StyledBadge>
            </div>

            <div className="text-xs text-doubleNickel-gray-500">
              {applicant.jobListing?.title} ·{' '}
              {formatInternationalPhoneNumber(applicant.phone)} ·{' '}
              {applicant.yearsOfExperience
                ? applicant.yearsOfExperience + ' years of experience'
                : 'No experience information available'}{' '}
              · {applicant.callCount} calls made
            </div>
          </div>
          <ActionIcon
            variant="subtle"
            size={20}
            className={'ml-auto opacity-0 group-hover:opacity-100'}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onPhoneCall({
                applicantId: applicant.applicantId,
                applicantFullName: buildFullName(applicant),
                phone: unformatPhoneNumber(applicant.phone),
              })
              close()
            }}
          >
            <IconPhoneCall className="fill-none stroke-doubleNickel-gray-700" />
          </ActionIcon>
          <ActionIcon
            variant="subtle"
            size={20}
            className={'opacity-0 group-hover:opacity-100'}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              navigate(
                routes.applicantDetails({
                  id: applicant.applicantId,
                  tab: 'activitylog',
                  activityType: 'SMS',
                })
              )
              close()
            }}
          >
            <IconMessage className="fill-none stroke-doubleNickel-gray-700" />
          </ActionIcon>
        </a>
      ))}
      {networkStatus === NetworkStatus.fetchMore && (
        <Loader
          size="sm"
          className="flex w-full items-center justify-center "
        />
      )}
      {applicants.length < applicantsData?.applicants.totalCount &&
        !loading && (
          <div ref={ref} className="opacity-0">
            Inview placeholder
          </div>
        )}
    </Modal>
  )
}

export default SearchModal
