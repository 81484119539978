export const GET_APPLICANTS = gql`
  query Applicants(
    $pageInfo: PageInfoInput
    $filters: ApplicantFiltersInput
    $stepFilters: StepFiltersInput
  ) {
    applicants(pageInfo: $pageInfo, filters: $filters) {
      totalCount
      items {
        applicantId
        firstName
        middleName
        lastName
        yearsOfExperience
        callCount
        applicationDate
        lastActionDate
        phone
        createdAt
        updatedAt
        hiringStage
        applicantStage
        notInterestedReason
        disqualifiedReason
        terminatedReason
        jobListing {
          jobListingId
          title
        }
        address {
          city
          state
        }
        steps(filters: $stepFilters) {
          items {
            stepId
            type
            status
          }
          totalCount
        }
      }
      pageInfo {
        offset
        limit
      }
    }
  }
`

export const GET_APPLICANT_BY_ID = gql`
  query Applicant($id: String!) {
    applicant(id: $id) {
      applicantId
      firstName
      middleName
      lastName
      email
      yearsOfExperience
      callCount
      applicationDate
      lastActionDate
      phone
      createdAt
      updatedAt
      hiringStage
      applicantStage
      applicantStageDate
      notInterestedReason
      disqualifiedReason
      terminatedReason
      eligibleForRehire
      isPermanentlyDisqualified
      jobListing {
        title
      }
      recruiter {
        employeeId
        firstName
        lastName
        jobTitle
        roleType
      }
      application {
        dob
        ssn
        applicationId
        license {
          licenseClass
          endorsements
        }
        accidents {
          createdAt
          accidentDate
          state
        }
        employmentExperiences {
          employmentExperienceId
          createdAt
          updatedAt
          startDate
          endDate
          companyName
        }
      }
      address {
        city
        state
      }
      steps {
        items {
          stepId
          createdAt
          updatedAt
          status
          type
          documents {
            documentId
            status
            userStatus
            category
            expirationDate
            fileName
          }
        }
      }
    }
  }
`

export const GET_APPLICANT_NAME_BY_ID = gql`
  query ApplicantName($id: String!) {
    applicant(id: $id) {
      firstName
      middleName
      lastName
    }
  }
`

export const GET_APPLICANT_METRICS = gql`
  query ApplicantMetrics($groupBy: String!, $filters: ApplicantFiltersInput) {
    applicantMetrics(groupBy: $groupBy, filters: $filters) {
      totalCount
      items {
        name
        value
      }
    }
  }
`

export const CREATE_APPLICANT = gql`
  mutation createApplicant($input: CreateApplicantInput!) {
    createApplicant(input: $input) {
      applicantId
    }
  }
`

export const CREATE_BASIC_APPLICANT = gql`
  mutation createBasicApplicant($input: CreateBasicApplicantInput!) {
    createBasicApplicant(input: $input) {
      applicantId
      application {
        applicationId
      }
    }
  }
`

export const UPDATE_APPLICANT = gql`
  mutation updateApplicant($id: String!, $input: UpdateApplicantInput!) {
    updateApplicant(id: $id, input: $input) {
      applicantId
    }
  }
`

export const UPDATE_APPLICANT_PHONE = gql`
  mutation updateApplicantPhone($id: String!, $phone: String!) {
    updateApplicantPhone(id: $id, phone: $phone) {
      applicantId
    }
  }
`

export const DOWNLOAD_CSV = gql`
  mutation DownloadCSV(
    $filters: ApplicantFiltersInput
    $pageInfo: PageInfoInput
  ) {
    downloadCSV(filters: $filters, pageInfo: $pageInfo) {
      fileContent
      fileName
    }
  }
`

export const BULK_STAGE_UPDATE = gql`
  mutation BulkStageUpdate(
    $applicants: [String!]!
    $input: ApplicantStageInput!
  ) {
    bulkStageUpdate(applicants: $applicants, input: $input) {
      applicants {
        applicantId
        success
        errorMsg
      }
    }
  }
`
