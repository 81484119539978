import { useEffect, useState } from 'react'

import { NetworkStatus } from '@apollo/client'
import { Divider, Loader, Tabs } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useInView } from 'react-intersection-observer'

import { navigate, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import CallTable from 'src/components/ActivityLogs/CallLog/CallTable'
import NoteLog from 'src/components/ActivityLogs/NoteLog/NoteLog'
import SMSLog from 'src/components/ActivityLogs/SMSLog/SMSLog'
import { SearchBox } from 'src/components/Inputs/SearchBox/SearchBox'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_ACTIVITY_LOGS } from 'src/graphql/activityLogs.graphql'
import { ActivityLogType } from 'src/graphql/types/activityLogs'
import { usePage } from 'src/hooks/usePage/usePage'
import { cn } from 'src/utils'

import ActivityLogInput from './components/ActivityLogInput/ActivityLogInput'
import { ActivityLogTimeline } from './components/ActivityLogTimeline/ActivityLogTimeline'
import CallDetailsDrawer from './components/CallDetailsDrawer'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const defaultFilters = {
  createdAt: null,
  activityLogTypes: [],
  searchText: '',
}

const mapLogTypes = {
  NOTE: 'Note',
  SMS: 'SMS',
  CALL: 'Call',
}

const ActivityLogTab = ({ applicant, activityType = 'ALL' }) => {
  const [drawerOpened, drawerHandlers] = useDisclosure(false)
  const [selectedCall, setSelectedCall] = useState(null)
  const [page, _] = usePage()
  const [filters, setFilters] = useState(defaultFilters)
  const [searchText, setSearchText] = useState('')
  const applicantId = applicant?.applicantId || null
  const [totalCountLabel, setTotalCountLabel] = useState('')
  const LIMIT = 20
  const { ref, inView } = useInView({
    threshold: 0,
  })

  const { ref: filteredRef, inView: filteredInView } = useInView({
    threshold: 0,
  })

  const {
    data: activityLogsResponse,
    networkStatus,
    fetchMore,
  } = useQuery(GET_ACTIVITY_LOGS, {
    skip: !applicantId,
    variables: {
      applicantId,
      pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
    },
    onError,
  })

  const {
    data: filteredActivityLogsResponse,
    networkStatus: filteredNetworkStatus,
    fetchMore: filteredFetchMore,
  } = useQuery(GET_ACTIVITY_LOGS, {
    skip: !applicantId,
    variables: {
      applicantId,
      filters,
      pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
    },
    onError,
    onCompleted: (data) => {
      const count = data?.activityLogs.totalCount
      const activity = mapLogTypes[activityType]
      setTotalCountLabel(
        count === 1 ? `1 ${activity}` : `${count} ${activity}s`
      )
    },
  })

  useEffect(() => {
    if (inView) {
      fetchMore({
        variables: {
          pageInfo: {
            offset: activityLogsResponse?.activityLogs?.items?.length ?? 0,
            limit: LIMIT,
          },
        },
      })
    }
  }, [inView])

  useEffect(() => {
    if (filteredInView) {
      filteredFetchMore({
        variables: {
          pageInfo: {
            offset:
              filteredActivityLogsResponse?.activityLogs?.items?.length ?? 0,
            limit: LIMIT,
          },
        },
      })
    }
  }, [filteredInView])

  const refetchQueries = [
    {
      query: GET_ACTIVITY_LOGS,
      variables: {
        applicantId,
        filters,
        pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
      },
    },
    {
      query: GET_ACTIVITY_LOGS,
      variables: {
        applicantId,
        pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
      },
    },
  ]

  const onSearch = (searchText: string) => {
    setFilters({
      ...filters,
      searchText,
    })
  }

  useEffect(() => {
    if (activityType !== 'ALL') {
      setFilters({
        ...filters,
        activityLogTypes: [activityType],
      })
    }
  }, [activityType])

  const handleSelect = (call) => {
    setSelectedCall(call)
    drawerHandlers.open()
  }

  return (
    <div className="flex h-[90%] flex-row gap-10">
      {drawerOpened && selectedCall && (
        <CallDetailsDrawer
          activityLog={selectedCall}
          drawerOpened={drawerOpened}
          drawerHandlers={drawerHandlers}
        />
      )}
      <div className="flex h-full w-full flex-col gap-4 overflow-auto">
        <div className="relative flex flex-col justify-between gap-4">
          <div className="flex flex-1 flex-row items-center gap-4">
            <div className="flex w-[15%] flex-col text-lg font-semibold text-doubleNickel-black">
              Activity Log
              <div className="text-sm font-normal text-doubleNickel-gray-600">
                {activityType === 'ALL'
                  ? `All events logged`
                  : `${totalCountLabel}`}
              </div>
            </div>

            <Tabs
              value={activityType}
              onChange={(value) => {
                navigate(
                  routes.applicantDetails({
                    id: applicantId,
                    tab: 'activitylog',
                    activityType: value,
                  })
                )
              }}
              variant="pills"
              classNames={{
                tab: `[&[data-active]]:text-doubleNickel-brand-500 [&[data-active]]:bg-doubleNickel-brand-25 font-semibold text-sm text-doubleNickel-gray-500 `,
              }}
            >
              <Tabs.List>
                <Tabs.Tab value="ALL">All</Tabs.Tab>
                <Tabs.Tab value="CALL">Calls</Tabs.Tab>
                <Tabs.Tab value="NOTE">Notes</Tabs.Tab>
                <Tabs.Tab value="SMS">Texts</Tabs.Tab>
              </Tabs.List>
            </Tabs>

            <SearchBox
              placeholder="Press Enter to search activity logs"
              onSearch={onSearch}
              value={searchText}
              setValue={setSearchText}
            />
          </div>
          <Divider />
        </div>

        {activityType === 'ALL' ? (
          <div className="flex w-full flex-col gap-8 overflow-hidden">
            <div className="overflow-auto">
              <ActivityLogTimeline
                applicantId={applicantId}
                activityLogsResponse={activityLogsResponse}
                networkStatus={networkStatus}
                refParent={ref}
              />
            </div>
          </div>
        ) : (
          <>
            {activityType === 'CALL' && (
              <div className="h-full overflow-hidden">
                <CallTable
                  applicantId={applicantId}
                  limit={100}
                  isTiny={true}
                />
              </div>
            )}
            {activityType !== 'CALL' && (
              <div className={cn('flex h-full flex-col-reverse overflow-auto')}>
                {filteredActivityLogsResponse?.activityLogs.items.map(
                  (activityLog) => {
                    if (activityLog.type === 'SMS') {
                      return (
                        <SMSLog
                          key={activityLog.activityLogId}
                          applicant={applicant}
                          activityLog={activityLog}
                        />
                      )
                    } else if (activityLog.type === 'NOTE') {
                      return (
                        <NoteLog
                          key={activityLog.activityLogId}
                          activityLog={activityLog}
                          smallVersion
                        />
                      )
                    }
                  }
                )}
                {filteredNetworkStatus === NetworkStatus.fetchMore && (
                  <Loader
                    size="sm"
                    className="flex w-full flex-row justify-center"
                  />
                )}
                {filteredActivityLogsResponse?.activityLogs.items.length <
                  filteredActivityLogsResponse?.activityLogs.totalCount && (
                  // TODO this conditional fails if a user adds a new activity log from the applicant sidebar
                  <div ref={filteredRef} className="opacity-0">
                    Inview placeholder
                  </div>
                )}
              </div>
            )}

            {(activityType == ActivityLogType.NOTE ||
              activityType == ActivityLogType.CALL ||
              activityType == ActivityLogType.SMS) && (
              <ActivityLogInput
                applicantId={applicantId}
                communication={activityType}
                refetchQueries={refetchQueries}
                onRegisterCall={() => {}}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ActivityLogTab
