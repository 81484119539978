import { useState } from 'react'

import { TextInput } from '@mantine/core'

import CallTable from 'src/components/ActivityLogs/CallLog/CallTable'
import PaginationArea from 'src/components/Navigation/PaginationArea/PaginationArea'
import StyledMultiselect from 'src/components/StyledMultiselect/StyledMultiselect'
import { CallDirection, CallStatus } from 'src/graphql/types/activityLogs'
import IconSearch from 'src/icons/IconSearch'
import { formatSnakeValue } from 'src/lib/formatters'

import DateRangeMenu from '../AnalyticsPage/components/DateRangeMenu'

const ActivitiesPage = () => {
  const [searchText, setSearchText] = useState('')
  const [callDirections, setCallDirections] = useState([])
  const [callTypes, setCallTypes] = useState([])
  const [callStatuses, setCallStatuses] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalCalls, setTotalCalls] = useState(0)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [showCalendar, setShowCalendar] = useState(false)

  return (
    <div className="flex h-full w-full flex-col gap-4 overflow-hidden px-5 pb-5">
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex w-full flex-col">
          <div className="text-lg font-semibold text-doubleNickel-gray-900">
            Call history
          </div>
          <div className="text-sm text-doubleNickel-gray-600">
            {totalCalls} calls
          </div>

          <div className="mt-4 flex w-full flex-row gap-2 overflow-y-hidden">
            <StyledMultiselect
              value={callDirections}
              placeholder={callDirections.length > 0 ? '' : 'Call Direction'}
              data={
                Object.keys(CallDirection).map((direction) => ({
                  value: direction,
                  label: formatSnakeValue(direction),
                })) || []
              }
              onChange={setCallDirections}
            />
            <StyledMultiselect
              value={callTypes}
              placeholder={callTypes.length > 0 ? '' : 'Call Type'}
              data={[
                { value: 'TWILIO', label: 'Recruiter' },
                { value: 'BLANDAI', label: 'Voice Agent' },
              ]}
              onChange={setCallTypes}
            />

            <StyledMultiselect
              value={callStatuses}
              placeholder={callStatuses.length > 0 ? '' : 'Call Status'}
              data={Object.keys(CallStatus).map((status) => ({
                value: status,
                label: formatSnakeValue(status),
              }))}
              onChange={setCallStatuses}
            />
            <TextInput
              className="ml-auto w-[300px]"
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.currentTarget.value)}
              leftSection={
                <IconSearch className="fill-none stroke-doubleNickel-gray-700" />
              }
            />
            <DateRangeMenu
              startDate={startDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              endDate={endDate}
              showCalendar={showCalendar}
              setShowCalendar={setShowCalendar}
              clearable={true}
              handleChange={(value) => {
                setStartDate(value[0])
                setEndDate(value[1])
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-hidden">
        <CallTable
          searchText={searchText}
          callDirections={callDirections}
          page={page}
          setTotalPages={setTotalPages}
          setTotalCalls={setTotalCalls}
          callStatuses={callStatuses}
          callTypes={callTypes}
          createdAt={{
            gte: startDate,
            lte: endDate,
          }}
        />
      </div>
      <PaginationArea
        page={page}
        totalPages={totalPages}
        onPageChange={setPage}
      />
    </div>
  )
}

export default ActivitiesPage
