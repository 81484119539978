import { useState, useEffect, useCallback } from 'react'

import { useLazyQuery } from '@apollo/client'
import { Button, Indicator, Tabs, Tooltip } from '@mantine/core'

import {
  navigate,
  routes,
  useParams,
  useLocation,
  back,
} from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import { useListOfApplicantsContext } from 'src/context/ApplicantListContext'
import { useCurrentCompanyContext } from 'src/context/CurrentCompanyContext'
import {
  usePhoneDevice,
  usePhoneDeviceDispatch,
} from 'src/context/PhoneDeviceContext'
import { GET_APPLICANT_BY_ID } from 'src/graphql/applicants.graphql'
import IconChevronLeft from 'src/icons/IconChevronLeft'
import { PhoneDeviceActionType } from 'src/lib/phone-device.reducer'
import { cn } from 'src/utils'

import ApplicantSidebar from './sidebar/ApplicantSidebar'
import ActivityLogTab from './tabs/ActivityLogTab/ActivityLogTab'
import ApplicationTab from './tabs/ApplicationTab/ApplicationTab'
import DocumentsTab from './tabs/DocumentsTab/DocumentsTab'
import TrainingTab from './tabs/TrainingTab/TrainingTab'
import VoETab from './tabs/VoETab/VoETab'

const ApplicantDetailsPage = ({ id: applicantId }) => {
  const dispatchPhoneAction = usePhoneDeviceDispatch()
  const phoneDeviceContext = usePhoneDevice()
  const currentCompany = useCurrentCompanyContext()
  const { applicantList } = useListOfApplicantsContext()
  const refetchQueries = [
    {
      query: GET_APPLICANT_BY_ID,
      variables: {
        id: applicantId,
      },
    },
  ]

  const [getApplicant, { data: applicantInfoData }] = useLazyQuery(
    GET_APPLICANT_BY_ID,
    {
      variables: {
        id: applicantId,
      },
    }
  )

  useEffect(() => {
    getApplicant()
  }, [])

  useEffect(() => {
    if (applicantInfoData && !applicantInfoData.applicant) {
      // Applicant not found, navigate to a fallback page
      navigate(routes.applicantNotFound())
    }
  }, [applicantInfoData])

  const applicantInfo = applicantInfoData?.applicant || null
  const pageTitle = applicantInfo
    ? `${applicantInfo.firstName} ${applicantInfo.lastName}`
    : 'Applicant Details'
  const defaultTab = 'application'
  const { tab, activityType, dqf = undefined } = useParams()

  const tabComponents = {
    application: (
      <ApplicationTab applicantId={applicantId} onRender={refetchQueries} />
    ),
    documents: (
      <DocumentsTab
        applicantId={applicantId}
        dqf={dqf}
        applicantInfo={applicantInfo}
      />
    ),
    voe: <VoETab applicantId={applicantId} applicant={applicantInfo} />,
    activitylog: (
      <ActivityLogTab applicant={applicantInfo} activityType={activityType} />
    ),
    training: <TrainingTab />,
  }

  const location = useLocation()
  const currentTab = tabComponents[tab] ? tab : defaultTab
  const [activeTab, setActiveTab] = useState<string | null>(currentTab)

  useEffect(() => {
    const pathParts = location.pathname.split('/')
    const newTab = pathParts[3]

    if (tabComponents[newTab]) {
      setActiveTab(newTab)
    } else {
      setActiveTab(defaultTab)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleTabChange = (newTab) => {
    if (tabComponents[newTab]) {
      setActiveTab(newTab)
      navigate(`/applicants/${applicantId}/${newTab}`)
    }
  }

  const onPhoneCall = useCallback(
    async ({ applicantId, applicantFullName, phone }) => {
      if (phoneDeviceContext?.phoneDevice?.isBusy) {
        return
      }
      dispatchPhoneAction({
        type: PhoneDeviceActionType.CALL,
        payload: {
          applicantId,
          phone,
          applicantFullName,
        },
      })
    },
    [phoneDeviceContext?.phoneDevice?.isBusy]
  )

  return (
    <div className="flex h-full flex-col gap-3 px-2">
      <Metadata title={pageTitle} description="ApplicantDetails page" />
      <div className="flex h-full flex-row-reverse gap-3 overflow-hidden">
        <div className="flex h-full w-[22.5vw] flex-col gap-4 border-l-[1px] px-5 pb-5">
          <ApplicantSidebar
            applicantId={applicantId}
            showNavigation={false}
            onPhoneCall={onPhoneCall}
          />
        </div>
        <div className="flex flex-1 flex-col gap-5">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="pills"
            classNames={{
              root: 'h-full',
              list: 'gap-4',
              tab: `[&[data-active]]:text-doubleNickel-brand-500 [&[data-active]]:bg-doubleNickel-brand-25 font-semibold text-sm text-doubleNickel-gray-500 hover:bg-doubleNickel-gray-100`,
            }}
          >
            <Tabs.List>
              <Button
                variant="subtle"
                className="mt-auto hover:bg-doubleNickel-brand-25"
                leftSection={
                  <IconChevronLeft className="fill-none stroke-doubleNickel-brand-500" />
                }
                onClick={() => {
                  const mostRecentApplicantListURL = localStorage.getItem(
                    'latestUrlWithFilteredApplicants'
                  )
                  if (mostRecentApplicantListURL) {
                    const url = '/' + mostRecentApplicantListURL.split('/')[3]
                    navigate(url)
                  } else {
                    back()
                  }
                }}
              >
                Back
              </Button>
              <Tabs.Tab value="application">Application</Tabs.Tab>
              <Tabs.Tab value="documents">Documents</Tabs.Tab>
              <Tabs.Tab value="voe">VOE</Tabs.Tab>
              <Tabs.Tab value="activitylog">Activity Log</Tabs.Tab>
              {currentCompany?.trainingEnabled && (
                <Indicator inline label="New" size={16} offset={8}>
                  <Tabs.Tab value="training">Training</Tabs.Tab>
                </Indicator>
              )}
              {applicantList && applicantList.includes(applicantId) && (
                <Tooltip
                  disabled={
                    applicantList.indexOf(applicantId) !==
                    applicantList.length - 1
                  }
                  label="No more applicants on the current page"
                >
                  <Button
                    variant="subtle"
                    className={cn(
                      'ml-auto mt-auto ',
                      applicantList.indexOf(applicantId) ==
                        applicantList.length - 1
                        ? 'opacity-50 hover:bg-transparent'
                        : ' hover:bg-doubleNickel-brand-25'
                    )}
                    rightSection={
                      <IconChevronLeft className="rotate-180 fill-none stroke-doubleNickel-brand-500" />
                    }
                    onClick={() => {
                      const currentIndex = applicantList.indexOf(applicantId)
                      const nextApplicantId = applicantList[currentIndex + 1]
                      if (nextApplicantId) {
                        navigate(`/applicants/${nextApplicantId}/${tab}`)
                      }
                    }}
                  >
                    Next
                  </Button>
                </Tooltip>
              )}
            </Tabs.List>
            <Tabs.Panel value={activeTab} className="h-full px-3 pt-5">
              {tabComponents[activeTab]}
            </Tabs.Panel>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default ApplicantDetailsPage
