import { useState } from 'react'

import { Avatar, Menu } from '@mantine/core'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import StyledMenu from 'src/components/Overlays/StyledMenu/StyledMenu'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  GET_MY_COMPANIES,
  UPDATE_CURRENT_EMPLOYEE_ID,
} from 'src/graphql/users.graphql'
import IconCheckmark from 'src/icons/IconCheckmark'
import IconChevronDown from 'src/icons/IconChevronDown'

const defaultCompany = {
  companyName: 'Double Nickel',
  employeeId: '',
}

const SidebarCompanyMenu = ({ sidebarExpanded }) => {
  const [selectedCompany, setSelectedCompany] = useState(defaultCompany)

  const { data: { getMyCompanies: companies } = {} } = useQuery(
    GET_MY_COMPANIES,
    {
      onCompleted: (data) => {
        setSelectedCompany(
          data.getMyCompanies.find((company) => company.isCurrentEmployeeId)
        )
      },
      onError: () => {
        toast('Something went wrong, please try again.', 'error')
      },
    }
  )

  const [updateCurrentEmployeeId] = useMutation(UPDATE_CURRENT_EMPLOYEE_ID, {
    onCompleted: (data) => {
      toast('Company changed successfully', 'success')
      const urls = [
        '/',
        '/applicants',
        '/jobs',
        '/voe-requests',
        '/settings',
        '/analytics',
        '/activities',
        '/communications',
      ]
      const currentUrl = window.location.pathname
      if (data?.updateCurrentEmployeeId) {
        if (!urls.includes(currentUrl)) {
          navigate(routes.dashboard())
          window.location.reload()
        } else {
          window.location.reload()
        }
      }
    },
    onError: () => {
      toast('Unable to switch companies. Please try again.', 'error')
    },
  })

  return (
    <StyledMenu offset={1} width={250} withArrow={!sidebarExpanded}>
      <Menu.Target>
        <div
          className={`flex w-full flex-row items-center justify-center rounded-lg  py-3   ${
            sidebarExpanded ? 'bg-white px-2 shadow hover:shadow-md' : ''
          }`}
        >
          {sidebarExpanded ? (
            <div className="flex w-full flex-row items-center justify-start gap-2">
              <Avatar color="blue" radius={'sm'} size={30} variant="filled">
                {selectedCompany.companyName[0]}
              </Avatar>
              <div className="flex w-[70%] flex-col justify-center overflow-auto text-nowrap">
                <div className="text-xs font-semibold text-doubleNickel-gray-800">
                  {selectedCompany.companyName}
                </div>
                <div className="text-xs text-doubleNickel-gray-600">
                  via Double Nickel
                </div>
              </div>
              <div className="ml-auto flex flex-col items-center justify-center">
                <IconChevronDown className="h-4 w-4 rotate-180 fill-none stroke-doubleNickel-gray-500" />
                <IconChevronDown className=" h-4 w-4 fill-none stroke-doubleNickel-gray-500" />
              </div>
            </div>
          ) : (
            <Avatar
              color="blue"
              radius="sm"
              size={40}
              variant="filled"
              className="shadow-md hover:shadow-lg"
            >
              {selectedCompany.companyName[0]}
            </Avatar>
          )}
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        {companies?.map((company) => (
          <Menu.Item
            key={company.companyName}
            onClick={() => {
              if (company.employeeId === selectedCompany.employeeId) return
              updateCurrentEmployeeId({
                variables: {
                  input: {
                    currentEmployeeId: company.employeeId,
                  },
                },
              })
            }}
          >
            <div className="flex flex-row items-center justify-between">
              {company.companyName}
              {selectedCompany.employeeId === company.employeeId && (
                <IconCheckmark className="h-4 w-4 fill-none stroke-doubleNickel-gray-500" />
              )}
            </div>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </StyledMenu>
  )
}

export default SidebarCompanyMenu
