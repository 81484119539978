export const SEND_CALL = gql`
  mutation SEND_CALL($applicantId: String!) {
    sendCall(applicantId: $applicantId) {
      activityLogId
    }
  }
`

export const GET_VOICE_AGENTS = gql`
  query GET_VOICE_AGENTS($pageInfo: PageInfoInput) {
    voiceAgents(pageInfo: $pageInfo) {
      items {
        voiceAgentId
        name
      }
      pageInfo {
        limit
        offset
      }
      totalCount
    }
  }
`
