import { useState, useEffect } from 'react'

import { Menu } from '@mantine/core'

import IconChevronDown from 'src/icons/IconChevronDown'
import {
  CountryConfig,
  CountryConfigItem,
  findIssuingCountry,
} from 'src/lib/country.utils'
import { buildFullName } from 'src/lib/formatters'
import { unformatSsn } from 'src/lib/ssn.utils'

import ApplicationNumberInput from '../components/ApplicationNumberInput'
import ApplicationPasswordInput from '../components/ApplicationPasswordInput'
import ApplicationScreenWrapper from '../components/ApplicationScreenWrapper'
import ApplicationTextInput from '../components/ApplicationTextInput'
import { formatDate, formatSsn, formatDateString } from '../utils/formatters'
import { useFormContext } from '../utils/FormContext'

const isMobile = window.innerWidth < 640

export const NameInformationScreen = ({ updateLoading }) => {
  const { form, goBack, handleNext, application } = useFormContext()

  return (
    <ApplicationScreenWrapper
      title={'What is your legal name?'}
      description={'Please confirm the following information.'}
      goBack={goBack}
      application={application}
      hideBackButton={true}
      updateLoading={updateLoading}
      saveButtontext={
        form.isDirty('firstName') ||
        form.isDirty('middleName') ||
        form.isDirty('lastName')
          ? 'Save'
          : 'Continue'
      }
      handleSubmit={() => {
        handleNext({
          firstName: form.values.firstName,
          middleName: form.values.middleName,
          lastName: form.values.lastName,
        })
      }}
    >
      <ApplicationTextInput
        placeholder="First Name"
        required
        label="First Name"
        {...form.getInputProps('firstName')}
      />
      <ApplicationTextInput
        label="Middle Name"
        placeholder="Middle Name"
        {...form.getInputProps('middleName')}
      />
      <ApplicationTextInput
        label="Last Name"
        required
        placeholder="Last Name"
        {...form.getInputProps('lastName')}
      />
    </ApplicationScreenWrapper>
  )
}

export const PersonalInformationScreen = () => {
  const { form, goBack, handleNext, updateLoading, application } =
    useFormContext()

  const existingIssuingCountry = application?.issuingCountry
    ? findIssuingCountry(application.issuingCountry)
    : undefined
  const [issuingCountryConfig, setIssuingCountry] = useState<CountryConfigItem>(
    existingIssuingCountry || CountryConfig.US
  )

  const leftSectionWidth = '80px'
  const leftSectionStyles = `flex flex-row items-center gap-1 text-nowrap px-2 font-medium text-doubleNickel-gray-800 hover:cursor-pointer ${
    isMobile ? 'text-lg' : 'text-sm'
  }`

  useEffect(() => {
    if (!form.values.ssn) return
    formatSsn(issuingCountryConfig.code, form.values.ssn, form)
  }, [issuingCountryConfig.code])

  return (
    <ApplicationScreenWrapper
      title={'Hello ' + buildFullName(application) + '!'}
      description={'Please complete the following information'}
      goBack={goBack}
      hideBackButton={true}
      application={application}
      updateLoading={updateLoading}
      saveButtontext={
        form.isDirty('email') ||
        form.isDirty('dob') ||
        form.isDirty('ssn') ||
        form.isDirty('availableForWork') ||
        form.isDirty('yearsOfExperience')
          ? 'Save'
          : 'Continue'
      }
      handleCancel={goBack}
      handleSubmit={() => {
        handleNext({
          email: form.values.email,
          dob: new Date(form.values.dob as string),
          issuingCountry: form.values.issuingCountry,
          ssn: unformatSsn(form.values.ssn),
          availableForWork: new Date(form.values.availableForWork as string),
          yearsOfExperience: parseFloat(form.values.yearsOfExperience),
        })
      }}
    >
      <ApplicationTextInput
        label="Email address"
        required
        placeholder="Email"
        {...form.getInputProps('email')}
      />
      <ApplicationTextInput
        label="Date of Birth"
        required
        key="dob"
        pattern="\d*"
        placeholder={'MM / DD / YYYY'}
        {...form.getInputProps('dob')}
        value={formatDateString(form.values.dob)}
        onChange={(event) => {
          formatDate('dob', event, form)
        }}
        error={form.errors['dob']}
      />
      <ApplicationPasswordInput
        label={issuingCountryConfig.ssnTitle}
        pattern="\d*"
        required
        placeholder={issuingCountryConfig.ssnFormatPlaceholder}
        leftSectionWidth={leftSectionWidth}
        leftSectionPointerEvents={'all'}
        leftSection={
          <Menu shadow="md">
            <Menu.Target>
              <div className={leftSectionStyles}>
                {issuingCountryConfig.label}
                <IconChevronDown className="h-4 fill-none stroke-doubleNickel-gray-400" />
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              {Object.values(CountryConfig).map((config) => (
                <Menu.Item
                  key={config.code}
                  onClick={() => setIssuingCountry(config)}
                >
                  <div>{config.label}</div>
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        }
        {...form.getInputProps('ssn')}
        onChange={(event) => {
          formatSsn(issuingCountryConfig.code, event.target.value, form)
        }}
      />
      <ApplicationTextInput
        label="Available Start Date"
        pattern="\d*"
        placeholder={'MM / DD / YYYY'}
        value={formatDateString(form.values.availableForWork)}
        onChange={(event) => {
          formatDate('availableForWork', event, form)
        }}
        error={form.errors.availableForWork}
      />
      <ApplicationNumberInput
        label="CDL Years of Experience"
        decimalScale={1}
        decimalSeparator="."
        placeholder="Example: 2.5"
        {...form.getInputProps('yearsOfExperience')}
      />
    </ApplicationScreenWrapper>
  )
}
