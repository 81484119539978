// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage

import { useEffect } from 'react'

import { datadogRum } from '@datadog/browser-rum'

import { Router, Route, PrivateSet, Private } from '@redwoodjs/router'

import { useAuth } from './auth'
import AdminContainer from './components/AdminContainer/AdminContainer'
import SkeletonLoader from './components/Feedback/SkeletonLoader/SkeletonLoader'
import DashboardLayout from './layouts/DashboardLayout/DashboardLayout'
import ActivitiesPage from './pages/ActivitiesPage/ActivitiesPage'
import { MainAccidentsScreen } from './pages/DriverApplicationPage/screens/AccidentsScreen'
import { CurrentAddressScreen } from './pages/DriverApplicationPage/screens/AddressScreen'
import ConsentScreen from './pages/DriverApplicationPage/screens/ConsentScreen'
import { MainEmploymentScreen } from './pages/DriverApplicationPage/screens/EmploymentScreen'
import { EquipmentScreen } from './pages/DriverApplicationPage/screens/EquipmentScreen'
import { LicenseScreen } from './pages/DriverApplicationPage/screens/LicenseScreen'
import { PersonalInformationScreen } from './pages/DriverApplicationPage/screens/PersonalInformationScreen'
import ReviewScreen from './pages/DriverApplicationPage/screens/ReviewScreen'
import SubmitScreen from './pages/DriverApplicationPage/screens/SubmitScreen'
import { FormProvider } from './pages/DriverApplicationPage/utils/FormContext'
import ShortFormPage from './pages/ShortFormPage/ShortFormPage'

const Routes = () => {
  const { currentUser, loading } = useAuth()
  const isAdmin = currentUser && currentUser.roles.includes('ADMIN')

  useEffect(() => {
    if (!loading && currentUser && currentUser.userId && (currentUser.roles.includes('RECRUITER') || currentUser.roles.includes('ADMIN'))) {
      datadogRum.setUser({
        id: currentUser.userId,
        email: currentUser.email,
        phone: currentUser.phone,
        roles: currentUser.roles,
        company: currentUser.companyId,
        companyName: currentUser.companyName,
      })
    }
  }, [currentUser, loading])

  return (
    <Router useAuth={useAuth}>
      <Route path="/voe-login" page={VoELoginPage} name="voeLogin" />
      <Route path="/external-voe-request" page={ExternalVoeRequestPage} name="externalVoeRequest" />
      <Route path="/forbidden" page={ForbiddenPage} name="forbidden" />
      <Route path="/logout" page={LogoutPage} name="logout" />
      <Route path="/short-form/{trackingLink}" page={ShortFormPage} name="shortForm" />
      <Route path="/login" page={LoginPage} name="login" />
      <Route notfound page={NotFoundPage} />
      <Route path="/driver-login" page={DriverLoginPage} name="driverLogin" />
      <PrivateSet unauthenticated="driverLogin" roles={['DRIVER', 'ADMIN']} wrap={[FormProvider]} whileLoadingAuth={SkeletonLoader} whileLoadingPage={SkeletonLoader}>
        <Route path="/driver-application/address-information" page={CurrentAddressScreen} name="addressInformation" />
        <Route path="/driver-application/license-information" page={LicenseScreen} name="licenseInformation" />
        <Route path="/driver-application/equipment-information" page={EquipmentScreen} name="equipmentInformation" />
        <Route path="/driver-application/accident-history" page={MainAccidentsScreen} name="accidentInformation" />
        <Route path="/driver-application/employment-history" page={MainEmploymentScreen} name="employmentInformation" />
        <Route path="/driver-application/consents" page={ConsentScreen} name="consentsInformation" />
        <Route path="/driver-application/review" page={ReviewScreen} name="reviewInformation" />
        <Route path="/driver-application/submit" page={SubmitScreen} name="submitInformation" />
        <Route path="/driver-application" page={PersonalInformationScreen} name="personalInformation" />
      </PrivateSet>
      <PrivateSet unauthenticated="voeLogin" roles={['EXTERNAL_COMPANY', 'ADMIN']}>
        <Route path="/voe-response" page={VoEResponsePage} name="voeResponse" />
      </PrivateSet>
      <PrivateSet wrap={DashboardLayout} unauthenticated="login" roles={['RECRUITER', 'ADMIN']} whileLoadingAuth={SkeletonLoader} whileLoadingPage={SkeletonLoader}>
        <Route path="/" page={DashboardPage} name="dashboard" />
        <Route path="/applicant-not-found" page={ApplicantNotFoundPage} name="applicantNotFound" />
        <Route path="/applicants/{id:String}" page={ApplicantDetailsPage} name="applicantDetails" />
        <Route path="/applicants/{id:String}/{tab:String}" page={ApplicantDetailsPage} name="applicantDetails" />
        <Route path="/applicants" page={ApplicantsPage} name="applicants" />
        <Route path="/jobs" page={JobsPage} name="jobs" />
        <Route path="/settings" page={SettingsPage} name="settings" />
        <Route path="/voe-requests/{id:String}" page={VoERequestDetailsPage} name="voeRequestDetails" />
        <Route path="/voe-requests" page={VoERequestsPage} name="voeRequests" />
        <Route path="/applicants/{id}/{tab}/{voeId}/detail" page={VoEResponseDetailPage} name="voeResponseDetails" />
        <Route path="/analytics" page={AnalyticsPage} name="analytics" />
        <Route path="/communications" page={ActivitiesPage} name="communications" />
      </PrivateSet>
    </Router>
  )
}

export default Routes
