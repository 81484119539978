import { useCallback, useEffect, useState } from 'react'

import { Button as MantineButton } from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import { MetaTags } from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'
import { useQuery } from '@redwoodjs/web'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { CREATE_BASIC_APPLICANT } from 'src/graphql/applicants.graphql'
import { GET_TRACKING_LINK_BY_LINK } from 'src/graphql/trackinglinks.graphql'
import { removeCountryCode, unformatPhoneNumber } from 'src/lib/phone.utils'

import ApplicationNumberInput from '../DriverApplicationPage/components/ApplicationNumberInput'
import ApplicationPhoneInput from '../DriverApplicationPage/components/ApplicationPhoneInput'
import ApplicationTextInput from '../DriverApplicationPage/components/ApplicationTextInput'
import Header from '../DriverApplicationPage/components/Header'
import ApplicationCompletedScreen from '../DriverApplicationPage/screens/ApplicationCompletedScreen'

const ShortFormPage = ({ trackingLink }) => {
  const [active, setActive] = useState(0)
  const [applicant, setApplicant] = useState(null)

  const schema = z.object({
    firstName: z
      .string()
      .min(2, { message: 'First name should have at least 2 letters' })
      .max(20, { message: 'First name should have at most 20 letters' }),
    lastName: z
      .string()
      .min(2, { message: 'Last name should have at least 2 letters' })
      .max(20, { message: 'Last name should have at most 20 letters' }),
    email: z.string().email({ message: 'Invalid email' }),
    phone: z
      .string()
      .refine(
        (data) => unformatPhoneNumber(removeCountryCode(data)).length === 10,
        {
          message: 'Phone number should have 10 digits',
        }
      ),
    yearsOfExperience: z
      .number()
      .multipleOf(0.1)
      .max(100, { message: 'Please enter accurate information' })
      .optional(),
  })

  const form = useForm({
    initialValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phone: '',
      yearsOfExperience: undefined,
    },
    validate: zodResolver(schema),
  })

  const { data: trackingLinkResponse } = useQuery(GET_TRACKING_LINK_BY_LINK, {
    variables: {
      link: trackingLink,
    },
    onError: () => {
      window.location.href = '/404'
    },
  })

  useEffect(() => {
    if (trackingLinkResponse?.trackingLinkByLink === null) {
      window.location.href = '/404'
    }
  }, [trackingLinkResponse])

  const [addApplicant, { loading: loadingSubmitApplicant }] = useMutation(
    CREATE_BASIC_APPLICANT,
    {
      onCompleted: (data) => {
        setApplicant(data?.createBasicApplicant)
      },
      onError: () => {
        toast('Short form failed, please try again later.', 'error')
      },
    }
  )

  useEffect(() => {
    if (applicant) {
      setActive((curr) => curr + 1)
    }
  }, [applicant])

  const submitApplicant = useCallback(
    (values) => {
      const input = {
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        email: values.email,
        phone: unformatPhoneNumber(values.phone),
        yearsOfExperience: values?.yearsOfExperience
          ? parseFloat(values.yearsOfExperience)
          : undefined,
        trackingLink,
      }
      addApplicant({ variables: { input } })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trackingLink]
  )

  if (
    !trackingLinkResponse ||
    trackingLinkResponse?.trackingLinkByLink === null
  ) {
    return null
  }

  const handleSubmission = (form) => {
    const validation = form.validate()
    if (!validation.hasErrors) {
      submitApplicant(form.values)
    }
  }

  return (
    <form
      // onSubmit={form.onSubmit((values) => submitApplicant(values))}
      className="flex h-[100dvh] flex-col justify-between gap-5 overflow-auto"
    >
      <MetaTags title="ShortForm" description="ShortForm page" />
      <Header
        companyId={trackingLinkResponse?.trackingLinkByLink?.company?.companyId}
      />
      {active === 0 && (
        <>
          <div className="flex flex-1 flex-col gap-5 px-4 sm:px-24">
            <div className="flex flex-1 flex-col gap-4">
              <div className="flex flex-col gap-4 sm:gap-5 sm:rounded-lg sm:border sm:border-doubleNickel-gray-200 sm:p-5">
                <div className="sm:text-md text-sm font-medium text-doubleNickel-gray-700 sm:font-semibold">
                  Fill out the form to receive more information:
                </div>
                <div className="flex flex-col gap-4">
                  <div className="grid grid-cols-1 gap-3 sm:grid-cols-3 sm:gap-4">
                    <ApplicationTextInput
                      label="First Name"
                      placeholder="Type your first name here"
                      required
                      {...form.getInputProps('firstName')}
                    />
                    <ApplicationTextInput
                      label="Middle Name"
                      placeholder="Type your middle name here"
                      {...form.getInputProps('middleName')}
                    />
                    <ApplicationTextInput
                      label="Last Name"
                      placeholder="Type your last name here"
                      required
                      {...form.getInputProps('lastName')}
                    />
                    <ApplicationTextInput
                      label="Email Address"
                      placeholder="Type your email here"
                      required
                      {...form.getInputProps('email')}
                    />
                    <ApplicationPhoneInput
                      label="Phone Number"
                      required
                      phone={form.values.phone}
                      error={form.errors.phone}
                      handleChange={(value) => {
                        form.setFieldValue('phone', value)
                      }}
                    />
                    <ApplicationNumberInput
                      label="CDL Years of Experience"
                      decimalScale={1}
                      decimalSeparator="."
                      step={0.5}
                      placeholder="Example: 2.5"
                      {...form.getInputProps('yearsOfExperience')}
                    />
                    {/* <ApplicationTextInput
                      label="CDL Experience in years"
                      type="number"
                      inputMode="decimal"
                      placeholder="Example: 2.5"
                      {...form.getInputProps('yearsOfExperience')}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 text-xs text-gray-600 sm:px-24">
            By submitting this form, you agree to our{' '}
            <a
              href="https://www.getdoublenickel.com/privacy-policy"
              className="text-doubleNickel-brand-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              href="https://www.getdoublenickel.com/terms-of-service"
              className="text-doubleNickel-brand-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            , and consent to receive text messages at the phone number provided.
            Reply STOP to cancel. Message frequency varies. Message and data
            rates may apply.
          </div>
          <div className="flex w-full flex-row items-center border-t-[1px] px-4 py-5 sm:px-24">
            <MantineButton
              classNames={{
                root: 'bg-doubleNickel-brand-500 rounded-lg h-11',
              }}
              loading={loadingSubmitApplicant}
              onClick={() => handleSubmission(form)}
              className="flex-1 sm:ml-auto sm:flex-none "
            >
              Submit
            </MantineButton>
          </div>
        </>
      )}
      {active === 1 && (
        <ApplicationCompletedScreen
          title={'Thank you for your submission!'}
          subTitle={`The next step is to complete your DoT application. Our recruiter will be in touch with you shortly to guide you through the process.`}
          showNavigationButton
          applicationId={applicant?.application?.applicationId}
          phone={unformatPhoneNumber(form.values.phone)}
        />
      )}
    </form>
  )
}

export default ShortFormPage
