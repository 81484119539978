import { Modal, Divider, TextInput } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import dayjs from 'dayjs'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import Button from 'src/components/Buttons/Button/Button'
import IconCalendar from 'src/icons/IconCalendar'

const HiredModal = ({ onSave, onClose }) => {
  const schema = z.object({
    content: z.string().min(0).max(250),
  })
  const form = useForm({
    initialValues: {
      applicantStageDate: dayjs(),
      content: '',
    },
    validate: zodResolver(schema),
  })
  const handleSubmit = (values) => {
    // TODO: Include these fields
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    // Don't send content if it's empty
    if (values.content.trim() === '') {
      delete values.content
    }
    onSave(values)
    onClose()
  }

  return (
    <Modal
      opened={true}
      onClose={onClose}
      classNames={{ title: 'text-lg font-bold' }}
      title="Disqualified"
      centered
    >
      <form
        className="grid grid-cols-2 gap-4"
        onSubmit={form.onSubmit(handleSubmit)}
      >
        <DatePickerInput
          label="Hired Date"
          placeholder="Pick date"
          valueFormat="MM/DD/YYYY"
          required
          leftSection={
            <IconCalendar className="fill-none stroke-doubleNickel-gray-500" />
          }
          {...form.getInputProps('applicantStageDate')}
        />
        <TextInput
          label="Notes"
          placeholder="Additional details or comments (optional)"
          className="col-span-2"
          {...form.getInputProps('content')}
        />
        <Divider className="col-span-2" />
        <Button text="Cancel" variant="outline" onClick={onClose} />
        <Button text="Save" type="submit" />
      </form>
    </Modal>
  )
}

export default HiredModal
