import { useState } from 'react'

import { ActionIcon } from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { v1 as uuid } from 'uuid'
import { z } from 'zod'

import DateInput from 'src/components/Inputs/DateInput/DateInput'
import StyledReadOnlyInput from 'src/components/Inputs/StyledReadOnlyInput/StyledReadOnlyInput'
import IconTrash from 'src/icons/IconTrash'
import { formatAddress } from 'src/lib/address.utils'

import PlacesAutocomplete from './AddressInput/PlacesAutocomplete'
import ApplicationCard from './ApplicationCard/ApplicationCard'
import SelectInput from './SelectInput/SelectInput'
import TextInput from './TextInput/TextInput'

const AccidentsCard = ({ values, handleUpdateApplication }) => {
  const [editing, setEditing] = useState(false)

  const defaultAccident = {
    accidentId: uuid(),
    accidentDate: undefined,
    city: '',
    state: '',
    country: '',
    description: '',
    isPreventable: false,
    isCommercialMotorVehicleInvolved: false,
  }

  const schema = z.object({
    accidents: z.array(
      z.object({
        accidentId: z.string().optional(),
        accidentDate: z.date().optional(),
        city: z.string().min(1).max(100),
        state: z.string().min(2).max(50),
        description: z.string().min(1).max(1000),
        isPreventable: z.boolean(),
        isCommercialMotorVehicleInvolved: z.boolean(),
      })
    ),
  })

  const form = useForm({
    initialValues: {
      accidents: values.map((accident) => ({
        ...(accident?.accidentId && { accidentId: accident.accidentId }),
        accidentDate: accident?.accidentDate
          ? new Date(accident.accidentDate)
          : undefined,
        city: accident?.city || '',
        state: accident?.state || '',
        country: accident?.country || 'USA',
        description: accident?.description || '',
        isPreventable: accident?.isPreventable || false,
        isCommercialMotorVehicleInvolved:
          accident?.isCommercialMotorVehicleInvolved || false,
      })),
    },
    validate: zodResolver(schema),
  })

  const handleAdd = () => {
    setEditing(true)
    form.insertListItem('accidents', defaultAccident)
  }
  const handleCancel = () => {
    form.setValues({
      accidents: values.map((accident) => ({
        ...(accident?.accidentId && { accidentId: accident.accidentId }),
        accidentDate: accident?.accidentDate
          ? new Date(accident.accidentDate)
          : undefined,
        city: accident?.city || '',
        state: accident?.state || '',
        country: accident?.country || '',
        description: accident?.description || '',
        isPreventable: accident?.isPreventable || false,
        isCommercialMotorVehicleInvolved:
          accident?.isCommercialMotorVehicleInvolved || false,
      })),
    })
    setEditing(false)
  }

  const handleDelete = (index) => {
    form.removeListItem(`accidents`, index)
  }

  const handleSave = () => {
    let updates = {}
    updates = {
      accidents: form.values.accidents.map((accident) => {
        const { accidentId, ...rest } = accident
        return rest
      }),
    }
    handleUpdateApplication(updates).then((res) => {
      if (res && !res.errors) {
        setEditing(false)
      }
    })
  }

  return (
    <ApplicationCard
      title={'Accidents & Violations'}
      editing={editing}
      isEmpty={form.values.accidents.length === 0}
      setEditing={setEditing}
      isSaveDisabled={!form.isValid()}
      handleSave={handleSave}
      handleCancel={handleCancel}
      handleAdd={handleAdd}
    >
      {form.values.accidents.map((value, index) => {
        return (
          <div
            key={value.accidentId || index}
            className="mb-4 flex flex-col gap-2 "
          >
            <div className="grid grid-cols-4 gap-4 rounded-lg border px-5 py-3">
              <div className="col-span-3 flex flex-col justify-center text-sm font-semibold text-doubleNickel-gray-700">
                Event {index + 1} of {form.values.accidents.length}
              </div>

              <ActionIcon
                className={`ml-auto h-8 w-8 border border-doubleNickel-gray-200 bg-doubleNickel-white shadow-sm hover:bg-doubleNickel-gray-200
                  ${editing ? 'visible' : 'invisible'}`}
                onClick={() => handleDelete(index)}
              >
                <IconTrash className="h-4 w-4 fill-none stroke-doubleNickel-error-500" />
              </ActionIcon>

              <DateInput
                editing={editing}
                label="Event Date"
                required={editing}
                value={form.values.accidents[index].accidentDate}
                maxDate={new Date()}
                defaultLevel="year"
                onChange={(value) => {
                  form.setFieldValue(`accidents.${index}.accidentDate`, value)
                }}
                classNames={{
                  label: 'text-sm font-medium text-doubleNickel-gray-700',
                  input: `[&[data-disabled]]:bg-doubleNickel-white [&[data-disabled]]:opacity-100 [&[data-disabled]]:text-doubleNickel-gray-900`,
                }}
              />

              {editing ? (
                <PlacesAutocomplete
                  label="City"
                  required={editing}
                  placeholder="Select a city from the dropdown"
                  value={form.values.accidents[index]}
                  types={['(cities)']}
                  handleChange={(value) => {
                    form.setFieldValue(`accidents.${index}.city`, value.city)
                    form.setFieldValue(`accidents.${index}.state`, value.state)
                    form.setFieldValue(
                      `accidents.${index}.country`,
                      value.country
                    )
                  }}
                />
              ) : (
                <StyledReadOnlyInput
                  label={'City'}
                  required={editing}
                  value={formatAddress(form.values.accidents[index])}
                />
              )}

              <SelectInput
                label="Preventable?"
                editing={editing}
                value={
                  form.values.accidents[index].isPreventable ? 'Yes' : 'No'
                }
                data={['Yes', 'No']}
                handleChange={(value) => {
                  form.setFieldValue(
                    `accidents.${index}.isPreventable`,
                    value === 'Yes'
                  )
                }}
              />
              <SelectInput
                label="Commercial Motor Vehicle?"
                editing={editing}
                value={
                  form.values.accidents[index].isCommercialMotorVehicleInvolved
                    ? 'Yes'
                    : 'No'
                }
                data={['Yes', 'No']}
                handleChange={(value) => {
                  form.setFieldValue(
                    `accidents.${index}.isCommercialMotorVehicleInvolved`,
                    value === 'Yes'
                  )
                }}
              />
              <TextInput
                className="col-span-4"
                label="Description"
                editing={editing}
                required={editing}
                value={form.values.accidents[index].description}
                handleChange={(value) => {
                  form.setFieldValue(`accidents.${index}.description`, value)
                }}
              />
            </div>
          </div>
        )
      })}
    </ApplicationCard>
  )
}

export default AccidentsCard
