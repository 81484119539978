import { useCallback } from 'react'

import { Drawer } from '@mantine/core'

import { useApplicantDrawerContext } from 'src/context/ApplicantDrawerContext'
import {
  usePhoneDevice,
  usePhoneDeviceDispatch,
} from 'src/context/PhoneDeviceContext'
import { PhoneDeviceActionType } from 'src/lib/phone-device.reducer'
import ApplicantSidebar from 'src/pages/ApplicantDetailsPage/sidebar/ApplicantSidebar'

const ApplicantDrawer = () => {
  const {
    opened: drawerOpened,
    applicantId,
    close,
  } = useApplicantDrawerContext()
  const dispatchPhoneAction = usePhoneDeviceDispatch()
  const phoneDeviceContext = usePhoneDevice()

  const onPhoneCall = useCallback(
    async ({ applicantId, applicantFullName, phone }) => {
      if (phoneDeviceContext?.phoneDevice?.isBusy) {
        return
      }
      dispatchPhoneAction({
        type: PhoneDeviceActionType.CALL,
        payload: {
          applicantId,
          phone,
          applicantFullName,
          // call,
        },
      })
    },
    []
  )

  return (
    <Drawer
      opened={drawerOpened}
      withCloseButton={false}
      onClose={close}
      position="right"
      size="22.5vw"
      lockScroll={false}
      overlayProps={{ backgroundOpacity: 0.1 }}
      transitionProps={{
        transition: 'slide-left',
        duration: 150,
        timingFunction: 'linear',
      }}
      classNames={{
        content: 'h-full p-0',
        root: 'p-0 ',
        inner: 'p-0',
      }}
    >
      <div className="h-[95vh]">
        <ApplicantSidebar
          applicantId={applicantId}
          showNavigation={true}
          onRegisterCall={() => {}}
          onPhoneCall={onPhoneCall}
        />
      </div>
    </Drawer>
  )
}

export default ApplicantDrawer
