import { useState, useRef } from 'react'

import { Box } from '@mantine/core'
import { Document, Page } from 'react-pdf'
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch'

import ZoomControls from 'src/components/Overlays/DocumentPreview/ZoomControls/ZoomControls'
import { cn } from 'src/utils'
import 'react-pdf/dist/esm/Page/TextLayer.css'

const options = {
  standardFontDataUrl: '../../../../../node_modules/pdfjs-dist/standard_fonts/',
  useSystemFonts: false,
}

export const PDFViewer = ({ url }) => {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null)

  const [zoomedIn, setZoomedIn] = useState(false)
  const [documentRotation, setDocumentRotation] = useState(0)
  const [numPages, setNumPages] = useState(0)

  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  return (
    <>
      <TransformWrapper
        ref={transformComponentRef}
        limitToBounds={false}
        centerZoomedOut
        wheel={{
          wheelDisabled: true,
          disabled: true,
        }}
        panning={{
          wheelPanning: zoomedIn,
        }}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <Box className="relative">
            <div className="sticky right-0 top-0 z-50">
              <ZoomControls
                zoomIn={() => {
                  setZoomedIn(true)
                  zoomIn(0.1)
                }}
                zoomOut={() => {
                  resetTransform()
                  setZoomedIn(false)
                }}
                setDocumentRotation={setDocumentRotation}
              />
            </div>
            <Box className="flex w-full justify-center">
              <TransformComponent>
                <Document
                  file={url}
                  renderMode="canvas"
                  onLoadSuccess={onLoadSuccess}
                  options={options}
                >
                  {[...Array(numPages).keys()].map((pageIndex) => (
                    <Page
                      key={pageIndex}
                      className={cn(
                        'flex w-full items-center justify-center',
                        documentRotation === 90 && 'rotate-90',
                        documentRotation === 180 && 'rotate-180',
                        documentRotation === 270 && '-rotate-90'
                      )}
                      pageIndex={pageIndex}
                      renderTextLayer={true}
                      renderAnnotationLayer={false}
                      scale={1}
                    />
                  ))}
                </Document>
              </TransformComponent>
            </Box>
          </Box>
        )}
      </TransformWrapper>
    </>
  )
}
