import React, { useEffect, useState } from 'react'

import { Combobox, Loader, TextInput, useCombobox } from '@mantine/core'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { formatAddress, parseReactGoogleAddress } from 'src/lib/address.utils'

const PlacesAutocomplete = ({
  label = null,
  value,
  required = false,
  types = ['street_address', 'subpremise', 'premise', 'route'],
  displayStreetOnly = false,
  handleChange,
  ...props
}) => {
  const [response, setResponse] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  //This handles the editable text in the TextInput
  const [address, setAddress] = useState(
    displayStreetOnly ? value?.street || '' : formatAddress(value)
  )

  const fetchPlaces = async (query) => {
    const API_KEY = process.env.GOOGLE_MAPS_API_KEY
    const url = 'https://places.googleapis.com/v1/places:autocomplete'
    const requestBody = {
      input: query,
      includedPrimaryTypes: types,
      includedRegionCodes: ['US', 'MX'],
    }

    try {
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Goog-Api-Key': API_KEY,
        },
        body: JSON.stringify(requestBody),
      })

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }

      const data = await res.json()
      setResponse(data.suggestions || [])
    } catch (error) {
      toast('Error fetching place', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const fetchPlaceDetails = async (placeId) => {
    const API_KEY = process.env.GOOGLE_MAPS_API_KEY
    const url = `https://places.googleapis.com/v1/places/${placeId}`

    try {
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Goog-Api-Key': API_KEY,
          'X-Goog-FieldMask': '*',
        },
      })

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }

      const data = await res.json()
      if (displayStreetOnly) {
        setAddress(data.displayName.text)
      }
      const placeObject = parseReactGoogleAddress(data)
      handleChange(placeObject)
    } catch (error) {
      toast('Error fetching place details', 'error')
    }
  }

  useEffect(() => {
    if (address.trim()) {
      setIsLoading(true)
      const timeoutId = setTimeout(() => fetchPlaces(address), 300)
      return () => clearTimeout(timeoutId)
    } else {
      setResponse([])
    }
  }, [address])

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  return (
    <div className="flex w-full flex-col gap-1">
      {label && (
        <div
          className={`flex flex-row justify-start gap-1 text-sm font-medium text-doubleNickel-gray-700`}
        >
          {label}
          {required && <span className="text-red-500">*</span>}
        </div>
      )}

      <Combobox store={combobox}>
        <Combobox.Target>
          <TextInput
            onFocus={() => combobox.openDropdown()}
            rightSection={isLoading && <Loader size={'xs'} />}
            value={address}
            onChange={(e) => {
              setAddress(e.currentTarget.value)
            }}
            {...props}
          />
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>
            {response.map((result) => (
              <Combobox.Option
                key={result.placePrediction.placeId}
                value={result.placePrediction.text.text}
                onClick={() => {
                  const value = result.placePrediction.text.text
                  setAddress(value)
                  const selectedPlace = response.find(
                    (result) => result.placePrediction.text.text === value
                  )
                  if (selectedPlace) {
                    fetchPlaceDetails(selectedPlace.placePrediction.placeId)
                  }
                  combobox.closeDropdown()
                }}
              >
                {result.placePrediction.text.text}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </div>
  )
}

export default PlacesAutocomplete
