import { createContext, useContext, useState } from 'react'

import { useDisclosure } from '@mantine/hooks'

const ApplicantDrawerContext = createContext(null)

const ApplicantDrawerContextProvider = ({ children }) => {
  const [opened, { open, close }] = useDisclosure(false)
  const [applicantId, setApplicantId] = useState(null)

  const handleOpen = (id) => {
    setApplicantId(id)
    open()
  }

  return (
    <ApplicantDrawerContext.Provider
      value={{
        opened,
        close,
        handleOpen,
        applicantId,
      }}
    >
      {children}
    </ApplicantDrawerContext.Provider>
  )
}

export function useApplicantDrawerContext() {
  return useContext(ApplicantDrawerContext)
}

export { ApplicantDrawerContext, ApplicantDrawerContextProvider }
