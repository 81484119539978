/* eslint-disable jsx-a11y/media-has-caption */
import { Drawer } from '@mantine/core'

import CallDetails from './CallDetails'

const CallDetailsDrawer = ({ activityLog, isOpen, close }) => {
  return (
    <Drawer
      opened={isOpen}
      withCloseButton={false}
      onClose={close}
      position="right"
      closeOnClickOutside={true}
      size="24vw"
      removeScrollProps={{ enabled: true, removeScrollBar: false }}
      overlayProps={{
        backgroundOpacity: 0.1,
      }}
      classNames={{
        title: 'text-base font-semibold text-doubleNickel-gray-900 ',
        body: 'flex flex-col gap-10 h-full',
      }}
    >
      <CallDetails activityLog={activityLog} close={close} />
    </Drawer>
  )
}

export default CallDetailsDrawer
