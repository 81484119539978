import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/dropzone/styles.css'
import 'mantine-datatable/styles.css'
import './index.css'
import { datadogRum } from '@datadog/browser-rum'
import { MantineProvider, ColorSchemeScript } from '@mantine/core'
// import { SpeedInsights } from '@vercel/speed-insights/react'

import { RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'
import { Toaster } from '@redwoodjs/web/dist/toast'

import Sentry from 'src/lib/sentry'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import { AuthProvider, useAuth } from './auth'
import AllContextProviders from './context'
import { graphQLClientConfig } from './graphql/graphQLClientConfig'
// import '@mantine/carousel/styles.css'

import 'src/lib/pdf-worker'

datadogRum.init({
  applicationId: process.env.DATADOG_RUM_APP_ID,
  clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: `double-nickel`,
  version: '0.1.0',
  env: process.env.APP_ENV,
  sessionSampleRate: 100,
  sessionReplaySampleRate: process.env.APP_ENV === 'production' ? 100 : 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
})

const App = () => (
  <Sentry.ErrorBoundary fallback={FatalErrorPage}>
    <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
      <AuthProvider>
        <ColorSchemeScript />
        <MantineProvider>
          <RedwoodApolloProvider
            useAuth={useAuth}
            graphQLClientConfig={graphQLClientConfig}
          >
            <AllContextProviders>
              <Routes />
              <Toaster />
            </AllContextProviders>
          </RedwoodApolloProvider>
        </MantineProvider>
        {/* <SpeedInsights /> */}
      </AuthProvider>
    </RedwoodProvider>
  </Sentry.ErrorBoundary>
)

export default App
