import { useCallback, useState } from 'react'

import { Checkbox, ActionIcon } from '@mantine/core'
import { useForm } from '@mantine/form'
import dayjs from 'dayjs'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import StyledReadOnlyInput from 'src/components/Inputs/StyledReadOnlyInput/StyledReadOnlyInput'
import IconTrash from 'src/icons/IconTrash'
import { formatAddress } from 'src/lib/address.utils'

import PlacesAutocomplete from './AddressInput/PlacesAutocomplete'
import ApplicationCard from './ApplicationCard/ApplicationCard'
import MonthInput from './MonthInput/MonthInput'
import TextInput from './TextInput/TextInput'

const AddressInformationCard = ({ values = [], handleUpdateApplication }) => {
  const [editing, setEditing] = useState(false)

  const schema = z.object({
    addresses: z.array(
      z.object({
        addressId: z.string().optional().nullable(),
        city: z
          .string()
          .min(1, { message: 'Invalid city, try being more specific' })
          .max(100, { message: 'The name of your city is too long' }),
        state: z
          .string()
          .min(2, { message: 'Invalid state, try being more specific' })
          .max(50, { message: 'The name of your state is too long' }),
        zipCode: z
          .string()
          .min(5, { message: 'Invalid zipcode, try being more specific' })
          .max(100, { message: 'Invalid zipcode, try being more specific' }),
        street: z
          .string()
          .min(1, { message: 'Invalid street, try being more specific' })
          .max(500, { message: 'Your street name is too long' }),
        country: z
          .string()
          .min(1, { message: 'Invalid country, try being more specific' })
          .max(100, { message: 'The name of your country is too long' }),
        startDate: z
          .date({ invalid_type_error: 'This is not a valid date' })
          .refine((data) => data < new Date(), {
            message: 'You must select a date in the past',
          }),
        endDate: z
          .date({ invalid_type_error: 'This is not a valid date' })
          .nullish(),
      })
    ),
  })

  const defaultAddress = {
    addressId: undefined,
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    startDate: undefined,
    endDate: new Date(),
  }

  const addressList = values.map((address) => ({
    addressId: address.addressId,
    city: address.city,
    state: address.state,
    zipCode: address.zipCode,
    street: address.street,
    country: address.country,
    startDate: address?.startDate
      ? new Date(address.startDate)
      : address.startDate,
    endDate: address?.endDate ? new Date(address.endDate) : address.endDate,
  }))

  const form = useForm({
    initialValues: {
      addresses: addressList,
    },
    validate: zodResolver(schema),
  })

  const handleCancel = () => {
    form.setValues({
      addresses: values.map((address) => ({
        ...(address?.addressId && { addressId: address.addressId }),
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        street: address.street,
        country: address.country,
        startDate: address?.startDate
          ? new Date(address.startDate)
          : address.startDate,
        endDate: address?.endDate ? new Date(address.endDate) : address.endDate,
      })),
    })
    setEditing(false)
  }

  const handleAdd = () => {
    form.insertListItem(`addresses`, defaultAddress)
  }

  const handleDelete = (index) => {
    form.removeListItem(`addresses`, index)
  }

  const handleSave = useCallback(() => {
    let updates = {}
    updates = {
      addresses: form.values.addresses,
    }

    handleUpdateApplication(updates).then((res) => {
      if (res && !res.errors) {
        setEditing(false)
        form.setValues({
          addresses: res.data.updateApplication.addresses.map((address) => ({
            addressId: address.addressId,
            city: address.city,
            state: address.state,
            zipCode: address.zipCode,
            street: address.street,
            country: address.country,
            startDate: address?.startDate
              ? new Date(address.startDate)
              : address.startDate,
            endDate: address.endDate
              ? new Date(address.endDate)
              : address.endDate,
          })),
        })
      }
    })
  }, [addressList])

  return (
    <ApplicationCard
      title={'Addresses'}
      editing={editing}
      isEmpty={form.values.addresses.length === 0}
      setEditing={setEditing}
      isSaveDisabled={!form.isValid()}
      handleSave={handleSave}
      handleCancel={handleCancel}
      handleAdd={handleAdd}
    >
      {form.values.addresses.map((value, index) => {
        return (
          <div
            key={index}
            className="mb-4 grid grid-cols-4 gap-4 rounded-lg border px-5 py-3"
          >
            <div className="col-span-3 flex flex-col justify-center text-sm font-semibold text-doubleNickel-gray-700">
              Address {index + 1} of {form.values.addresses.length}
            </div>

            <ActionIcon
              className={`ml-auto h-8 w-8 border border-doubleNickel-gray-200 bg-doubleNickel-white shadow-sm hover:bg-doubleNickel-gray-200
                  ${editing ? 'visible' : 'invisible'}`}
              onClick={() => handleDelete(index)}
            >
              <IconTrash className="h-4 w-4 fill-none stroke-doubleNickel-error-500" />
            </ActionIcon>
            {editing ? (
              <div className="col-span-2">
                <PlacesAutocomplete
                  label={'Address'}
                  required
                  value={form.values.addresses[index]}
                  handleChange={(value) => {
                    form.setFieldValue(`addresses.${index}`, value)
                  }}
                />
              </div>
            ) : (
              <StyledReadOnlyInput
                className="col-span-2 flex flex-col gap-3"
                label="Address"
                value={formatAddress(form.values.addresses[index])}
              />
            )}

            <MonthInput
              label="Start Date"
              editing={editing}
              value={value.startDate}
              required={editing}
              _maxDate={
                value.endDate
                  ? new Date(value.endDate).toISOString().split('T')[0]
                  : new Date().toISOString().split('T')[0]
              }
              handleChange={(value) => {
                form.setFieldValue(`addresses.${index}.startDate`, value)
              }}
            />

            {editing ? (
              <div className="flex flex-col gap-2">
                <MonthInput
                  label="End Date"
                  editing={true}
                  disabled={value.endDate === null}
                  value={value.endDate}
                  _maxDate={new Date().toISOString().split('T')[0]}
                  handleChange={(value) => {
                    form.setFieldValue(`addresses.${index}.endDate`, value)
                  }}
                />
                <Checkbox
                  label="Current address"
                  size="xs"
                  checked={
                    value.endDate === undefined || value.endDate === null
                  }
                  onClick={() => {
                    form.setFieldValue(
                      `addresses.${index}.endDate`,
                      value.endDate ? null : new Date()
                    )
                  }}
                />
              </div>
            ) : (
              <TextInput
                label="End Date"
                editing={false}
                value={
                  value.endDate
                    ? dayjs(value.endDate).format('MM/YYYY')
                    : 'Current'
                }
              />
            )}
            {/*
            {editing && (
              <div className="col-span-1 flex flex-col items-center justify-center">
                <Button
                  variant="light"
                  color="red"
                  leftSection={
                    <IconTrash className="h-4 w-4 fill-none stroke-doubleNickel-error-500" />
                  }
                  onClick={() => handleDelete(index)}
                >
                  Delete
                </Button>
              </div>
            )}
            {index < form.values.addresses.length - 1 && (
              <Divider className="col-span-8 mb-8" />
            )} */}
          </div>
        )
      })}
    </ApplicationCard>
  )
}

export default AddressInformationCard
