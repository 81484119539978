import { ApplicantDrawerContextProvider } from './ApplicantDrawerContext'
import { ApplicantListContextProvider } from './ApplicantListContext'
import { CurrentCompanyContextProvider } from './CurrentCompanyContext'
import { RecruitersContextProvider } from './RecruitersContext'

const DashboardContexts = ({ children }) => {
  return (
    <RecruitersContextProvider>
      <CurrentCompanyContextProvider>
        <ApplicantDrawerContextProvider>
          <ApplicantListContextProvider>
            {children}
          </ApplicantListContextProvider>
        </ApplicantDrawerContextProvider>
      </CurrentCompanyContextProvider>
    </RecruitersContextProvider>
  )
}

export default DashboardContexts
