import { forwardRef } from 'react'

import { NumberInput, NumberInputProps } from '@mantine/core'

export const ApplicationNumberInput = forwardRef<
  HTMLInputElement,
  NumberInputProps
>((props, ref) => {
  const labelStyles = 'text-sm text-doubleNickel-gray-600 font-medium'
  const wrapperStyles = 'border-b-[1px] border-doubleNickel-gray-300'
  const inputStyles = `text-base font-medium`
  const rootStyles = `w-full`

  return (
    <NumberInput
      ref={ref}
      variant="unstyled"
      classNames={{
        root: rootStyles,
        label: labelStyles,
        wrapper: wrapperStyles,
        input: inputStyles,
      }}
      {...props}
    />
  )
})

export default ApplicationNumberInput
