import { useState, useEffect } from 'react'

import { ActionIcon, Select } from '@mantine/core'

import Button from 'src/components/Buttons/Button/Button'
import DateInput from 'src/components/Inputs/DateInput/DateInput'
import StyledReadOnlyInput from 'src/components/Inputs/StyledReadOnlyInput/StyledReadOnlyInput'
import {
  LicenseClass,
  LicenseClassLabels,
  MexicanLicenseClass,
  MexicanLicenseClassLabels,
} from 'src/graphql/types/licenses'
import IconEdit from 'src/icons/IconEdit'
import { getEndorsementsByLicenseClass } from 'src/lib/license.utils'

import PlacesAutocomplete from '../AddressInput/PlacesAutocomplete'
import MultiSelectInput from '../MultiSelectInput/MultiSelectInput'
import SelectInput from '../SelectInput/SelectInput'
import TextInput from '../TextInput/TextInput'

const QuestionSelect = ({ label, editing, value, onChange }) => {
  const answer = value === undefined ? '-' : value ? 'Yes' : 'No'

  return (
    <div className="col-span-3 mt-3 flex flex-row items-center justify-between text-sm font-medium text-doubleNickel-gray-700">
      <div>{label}</div>
      {editing ? (
        <Select
          w={150}
          variant="unstyled"
          placeholder="Select an option"
          value={value !== undefined ? value.toString() : undefined}
          data={[
            {
              value: 'true',
              label: 'Yes',
            },
            {
              value: 'false',
              label: 'No',
            },
          ]}
          onChange={(event) => {
            onChange(event === 'true')
          }}
        />
      ) : (
        <div className="text-sm font-semibold text-doubleNickel-gray-700">
          {answer}
        </div>
      )}
    </div>
  )
}

const LicenseInformationCard = ({
  values: appValues = [],
  handleUpdateApplication,
}) => {
  const [values, setValues] = useState({ ...appValues[0] })
  const [editing, setEditing] = useState(false)
  const [filteredEndorsements, setFilteredEndorsements] = useState([])
  const showEdorsements = values.country === 'US'
  const licenseClassData =
    values.country === 'MX'
      ? Object.values(MexicanLicenseClass).map((value) => {
          return {
            value: value,
            label: MexicanLicenseClassLabels[value],
          }
        })
      : Object.values(LicenseClass).map((value) => {
          return {
            value: value,
            label: LicenseClassLabels[value],
          }
        })

  const handleCancel = () => {
    setValues({ ...appValues[0] })
    setEditing(false)
  }

  const handleSave = () => {
    let updates = {}
    updates = { license: { ...values } }
    handleUpdateApplication(updates).then((res) => {
      if (!res.errors) {
        setEditing(false)
      }
    })
  }

  useEffect(() => {
    const availableEndorsements = getEndorsementsByLicenseClass(
      values.licenseClass
    )
    const currentEndorsements = values.endorsements || []
    const updatedEndorsements = currentEndorsements.filter((endorsement) =>
      availableEndorsements.includes(endorsement)
    )

    setFilteredEndorsements(getEndorsementsByLicenseClass(values.licenseClass))
    setValues({ ...values, endorsements: updatedEndorsements })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.licenseClass])

  return (
    <div
      className={`flex w-full flex-col gap-4 rounded-lg border border-doubleNickel-gray-200 p-4`}
    >
      <div className=" text-md flex flex-row items-center justify-between font-semibold text-doubleNickel-black">
        License Information
        {!editing && (
          <ActionIcon
            className="h-8 w-8 border border-doubleNickel-gray-200 bg-doubleNickel-white shadow-sm hover:bg-doubleNickel-gray-200"
            onClick={() => setEditing(true)}
          >
            <IconEdit className="h-4 w-4 fill-none stroke-doubleNickel-gray-500" />
          </ActionIcon>
        )}
      </div>
      <div className={`grid grid-cols-3 gap-2`}>
        <TextInput
          label="License Number"
          editing={editing}
          value={values.licenseNumber}
          handleChange={(value) =>
            setValues({ ...values, licenseNumber: value })
          }
        />

        {editing ? (
          <PlacesAutocomplete
            label={'State'}
            placeholder="Select a state from the dropdown"
            value={values}
            types={['administrative_area_level_1']}
            handleChange={(value) => {
              const countryChanged = value.country !== values.country
              setValues({
                ...values,
                state: value.state,
                country: value.country,
                ...(countryChanged && { licenseClass: null }),
                ...(countryChanged && { endorsements: [] }),
              })
            }}
          />
        ) : (
          <StyledReadOnlyInput label={'State'} value={values.state} />
        )}

        <SelectInput
          label="License Class"
          editing={editing}
          value={values.licenseClass}
          data={licenseClassData}
          handleChange={(value) => {
            setValues({ ...values, licenseClass: value })
          }}
        />
        {showEdorsements && (
          <>
            {editing ? (
              <MultiSelectInput
                label="Endorsements"
                editing={editing}
                value={values.endorsements}
                data={filteredEndorsements}
                handleChange={(endorsements) => {
                  setValues({ ...values, endorsements })
                }}
              />
            ) : (
              <StyledReadOnlyInput
                label={'Endorsements'}
                value={values.endorsements?.join(', ')}
              />
            )}
          </>
        )}

        <DateInput
          label="Expiration Date"
          editing={editing}
          value={values.expirationDate}
          onChange={(value) => {
            setValues({ ...values, expirationDate: value })
          }}
        />

        <DateInput
          label="Medical Expiration Date"
          editing={editing}
          value={values.medicalExamExpirationDate}
          onChange={(value) => {
            setValues({ ...values, medicalExamExpirationDate: value })
          }}
        />

        <QuestionSelect
          label="Have they had any license, permit, or privilege ever been suspended, revoked,
        or denied?"
          editing={editing}
          value={values.isLicenseSuspended}
          onChange={(value) =>
            setValues({ ...values, isLicenseSuspended: value })
          }
        />
      </div>
      {editing && (
        <div className="flex flex-row gap-4">
          <Button
            text="Cancel"
            variant="outline"
            className="ml-auto"
            onClick={handleCancel}
          />
          <Button text="Save" onClick={handleSave} />
        </div>
      )}
    </div>
  )
}

export default LicenseInformationCard
