import { useEffect, useState } from 'react'

import {
  LicenseClass,
  LicenseClassLabels,
  MexicanLicenseClass,
  MexicanLicenseClassLabels,
} from 'src/graphql/types/licenses'
import { getEndorsementsByLicenseClass } from 'src/lib/license.utils'

import ApplicationAddressInput from '../components/ApplicationAddressInput'
import ApplicationMultiSelectInput from '../components/ApplicationMultiSelectInput'
import ApplicationRadioQuestion from '../components/ApplicationRadioQuestion'
import ApplicationScreenWrapper from '../components/ApplicationScreenWrapper'
import ApplicationSelectInput from '../components/ApplicationSelectInput'
import ApplicationTextInput from '../components/ApplicationTextInput'
import { formatDate, formatDateString } from '../utils/formatters'
import { useFormContext } from '../utils/FormContext'

const EndorsementDescriptions = {
  H: 'Hazardous Materials (Hazmat)',
  N: 'Tank Vehicles',
  X: 'Combination of Tank Vehicle and Hazardous Materials',
  P: 'Passenger Vehicles',
  S: 'School Buses',
  T: 'Double/Triple Trailers',
  V: 'Tank Vehicles Carrying Hazardous Materials',
  L: 'Air Brake Restriction',
}

export const LicenseScreen = () => {
  const { form, goBack, handleNext, updateLoading, application } =
    useFormContext()

  const [hasTWICCard, setHasTWICCard] = useState(
    application?.twicCard?.cardNumber || application?.twicCard?.expirationDate
  )
  const [endorsementDisabled, setEndorsementDisabled] = useState(false)
  const [medicalExamDisabled, setMedicalExamDisabled] = useState(false)
  const classTypes = Object.values(LicenseClass).map((licenseClass) => ({
    value: licenseClass,
    label: LicenseClassLabels[licenseClass],
  }))
  classTypes.unshift({
    value: null,
    label: 'Select a class type',
  })

  const mexicanClassTypes = Object.values(MexicanLicenseClass).map(
    (licenseClass) => ({
      value: licenseClass,
      label: MexicanLicenseClassLabels[licenseClass],
    })
  )
  mexicanClassTypes.unshift({
    value: null,
    label: 'Select a class type',
  })

  const handleClassTypeChange = (value) => {
    form.setFieldValue('license.licenseClass', value)
    if (value === LicenseClass.NO_CDL) {
      form.setFieldValue('license.endorsements', [])
      form.setFieldValue('license.medicalExamExpirationDate', null)
      setEndorsementDisabled(true)
      setMedicalExamDisabled(true)
    } else {
      if (endorsementDisabled) {
        setEndorsementDisabled(false)
      }
      if (medicalExamDisabled) {
        form.setFieldValue('license.medicalExamExpirationDate', undefined)
        setMedicalExamDisabled(false)
      }
    }
  }

  useEffect(() => {
    // Ensure handleClassTypeChange is only called when there's a valid initial class type
    const initialClassType = form.values.license.licenseClass
    if (initialClassType) {
      handleClassTypeChange(initialClassType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const verifyEndorsements = () => {
    const availableEndorsements = getEndorsementsByLicenseClass(
      form.values.license.licenseClass
    )
    const temp = form.values.license.endorsements
    const filteredEndorsements = temp.filter((endorsement) =>
      availableEndorsements.includes(endorsement)
    )
    form.setFieldValue('license.endorsements', filteredEndorsements)
  }

  useEffect(() => {
    if (form.values.license.licenseClass === LicenseClass.NO_CDL) return
    verifyEndorsements()
  }, [form.values.license.licenseClass])

  return (
    <ApplicationScreenWrapper
      title={'License Information'}
      goBack={goBack}
      updateLoading={updateLoading}
      application={application}
      handleCancel={goBack}
      handleSubmit={() => {
        handleNext({
          license: {
            ...form.values.license,
          },
          twicCard: {
            ...form.values.twicCard,
          },
        })
      }}
    >
      <ApplicationAddressInput
        required
        applicationInput
        label="What is the issuing state?"
        placeholder="Select a state from the dropdown"
        value={form.values.license}
        types={['administrative_area_level_1']}
        error={form.errors[`license.state`]}
        handleChange={(value) => {
          form.setFieldValue(`license.state`, value.state)
          form.setFieldValue(`license.country`, value.country)
          if (value.country !== form.values.license.country) {
            form.setFieldValue(`license.licenseClass`, null)
            form.setFieldValue(`license.endorsements`, [])
          }
        }}
      />
      <ApplicationTextInput
        label="What is your license number?"
        required
        placeholder={'License Number'}
        {...form.getInputProps('license.licenseNumber')}
      />
      <ApplicationTextInput
        label="What is your license's expiration date?"
        required
        pattern="\d*"
        placeholder={'MM / DD / YYYY'}
        value={formatDateString(form.values.license.expirationDate)}
        onChange={(event) => {
          formatDate(`license.expirationDate`, event, form)
        }}
        error={form.errors[`license.expirationDate`] ?? ''}
      />
      <ApplicationSelectInput
        label="What is the class type?"
        key={form.values.license?.country}
        required
        data={
          form.values.license?.country === 'MX' ? mexicanClassTypes : classTypes
        }
        value={form.values.license.licenseClass}
        error={form.errors[`license.licenseClass`] ?? ''}
        onChange={(event) => {
          handleClassTypeChange(event.currentTarget.value)
        }}
      />
      <ApplicationTextInput
        label="When does your medical exam expire?"
        pattern="\d*"
        placeholder={'MM / DD / YYYY'}
        disabled={medicalExamDisabled}
        required={!medicalExamDisabled}
        value={formatDateString(form.values.license.medicalExamExpirationDate)}
        onChange={(event) => {
          formatDate(`license.medicalExamExpirationDate`, event, form)
        }}
        error={form.errors[`license.medicalExamExpirationDate`] ?? ''}
      />

      <div
        className={
          form.values.license?.country === 'US'
            ? 'visible w-full'
            : 'visible hidden sm:invisible sm:block'
        }
      >
        <ApplicationSelectInput
          className="sm:hidden"
          label="What endorsements do you have?"
          multiple
          disabled={endorsementDisabled}
          data={getEndorsementsByLicenseClass(
            form.values.license.licenseClass
          ).map((endorsement) => ({
            value: endorsement,
            label: endorsement + ' - ' + EndorsementDescriptions[endorsement],
          }))}
          onChange={(event) => {
            const temp = Array.from(event.target.selectedOptions)
            form.setFieldValue(
              'license.endorsements',
              temp.map((option) => option.value)
            )
          }}
          value={form.values.license.endorsements}
          error={form.errors[`license.endorsements`] ?? ''}
        />
        <ApplicationMultiSelectInput
          className="hidden sm:inline"
          searchable
          label="What endorsements do you have?"
          disabled={endorsementDisabled}
          clearable
          data={getEndorsementsByLicenseClass(
            form.values.license.licenseClass
          ).map((endorsement) => ({
            value: endorsement,
            label: endorsement + ' - ' + EndorsementDescriptions[endorsement],
          }))}
          {...form.getInputProps('license.endorsements')}
          error={form.errors[`license.endorsements`] ?? ''}
        />
      </div>
      <ApplicationRadioQuestion
        question="Do you have a TWIC card?"
        required
        value={hasTWICCard ? 'true' : 'false'}
        handleChange={(e) => {
          if (e === 'false') {
            setHasTWICCard(false)
            form.setFieldValue('twicCard.cardNumber', null)
            form.setFieldValue('twicCard.expirationDate', null)
          } else {
            setHasTWICCard(true)
          }
        }}
      />

      {hasTWICCard && (
        <>
          <ApplicationTextInput
            label="What is your TWIC number?"
            placeholder={'TWIC number'}
            {...form.getInputProps('twicCard.cardNumber')}
          />
          <ApplicationTextInput
            label="What is your TWIC card's expiration date?"
            pattern="\d*"
            placeholder={'MM / DD / YYYY'}
            value={formatDateString(form.values.twicCard.expirationDate)}
            onChange={(event) => {
              formatDate(`twicCard.expirationDate`, event, form)
            }}
            error={form.errors[`twicCard.expirationDate`] ?? ''}
          />
        </>
      )}
      <ApplicationRadioQuestion
        required
        question="Have you had any license, permit, or privilege ever been suspended, revoked, or denied?"
        value={form.values.license?.isLicenseSuspended ? 'true' : 'false'}
        handleChange={(e) => {
          form.setFieldValue('license.isLicenseSuspended', e === 'true')
        }}
      />
    </ApplicationScreenWrapper>
  )
}
