import { Box, Kbd } from '@mantine/core'
import { useOs } from '@mantine/hooks'

import IconSearch from 'src/icons/IconSearch'

const GlobalSearchBar = ({ handleClick }) => {
  const os = useOs()

  return (
    <Box
      className="flex w-64 items-center gap-3 rounded-lg border border-gray-200 px-2 py-1 text-sm text-doubleNickel-gray-400 hover:cursor-pointer"
      onClick={handleClick}
    >
      <IconSearch className="h-4 w-4 fill-none stroke-doubleNickel-gray-400 text-gray-400" />
      Search
      <div className="ml-auto flex items-center gap-2">
        <Kbd size="xs">{os === 'macos' ? '⌘' : 'Ctrl'}</Kbd> +
        <Kbd size="xs">k</Kbd>
      </div>
    </Box>
  )
}

export default GlobalSearchBar
