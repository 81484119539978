import { Modal, MultiSelect, Tooltip, Divider, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  CREATE_JOB_LISTING,
  GET_JOB_LISTINGS,
} from 'src/graphql/joblistings.graphql'
import { JobStatus } from 'src/graphql/types/jobListings'
import PlacesAutocomplete from 'src/pages/ApplicantDetailsPage/tabs/ApplicationTab/components/AddressInput/PlacesAutocomplete'

const CreateJobListingModal = ({ isModalOpen, onModalClose, recruiters }) => {
  const form = useForm({
    initialValues: {
      title: '',
      address: null,
      assignedRecruiters: [],
    },
    validate: {
      title: (value) => (value ? null : 'Job title is required'),
      address: (value) =>
        value ? null : 'Please select a location from the dropdown',
      assignedRecruiters: (value) =>
        value.length > 0 ? null : 'At least one recruiter is required',
    },
  })

  const [createListing] = useMutation(CREATE_JOB_LISTING, {
    refetchQueries: [GET_JOB_LISTINGS],
    onCompleted: () => {
      toast('Job created successfully!', 'success')
    },
    onError: (error) => {
      toast(`${error.name}: Something went wrong, please try again.`, 'error')
    },
  })

  const handleSubmit = (values) => {
    const input = {
      title: values.title,
      address: values.address,
      recruiters: values.assignedRecruiters,
      jobPostingDate: new Date(),
      status: JobStatus.ACTIVE,
    }

    createListing({ variables: { input } })
    form.reset()
    onModalClose()
  }

  return (
    <Modal
      opened={isModalOpen}
      onClose={onModalClose}
      classNames={{ title: 'text-lg font-bold' }}
      title="Create New Job Listing"
      centered
      size="37%"
    >
      <form
        className="grid grid-cols-1 gap-4"
        onSubmit={form.onSubmit(handleSubmit)}
      >
        <TextInput
          label="Job Listing Title"
          placeholder="Enter a job listing title"
          required
          {...form.getInputProps('title')}
          classNames={{
            label: 'text-sm font-medium text-doubleNickel-gray-700',
          }}
        />

        <PlacesAutocomplete
          label="Location"
          required
          placeholder="Select a location from the dropdown"
          value={form.values.address}
          error={form.errors.address}
          handleChange={(value) => {
            form.setFieldValue(`address`, value)
          }}
        />
        <Tooltip
          label="No recruiters were found"
          disabled={recruiters.length > 0}
        >
          <MultiSelect
            label="Recruiters"
            placeholder="Select recruiters to assign"
            withAsterisk
            required
            disabled={recruiters.length === 0}
            data={recruiters}
            {...form.getInputProps('assignedRecruiters')}
            classNames={{
              label: 'text-xs font-medium text-doubleNickel-gray-700',
            }}
          />
        </Tooltip>
        <Divider />
        <div className="grid grid-cols-2 gap-3">
          <Button text="Cancel" variant="outline" onClick={onModalClose} />
          <Button text="Save" type="submit" />
        </div>
      </form>
    </Modal>
  )
}

export default CreateJobListingModal
