import { createContext, useContext, useState } from 'react'

const ApplicantListContext = createContext(null)

const ApplicantListContextProvider = ({ children }) => {
  const [applicantList, setApplicantList] = useState([])

  return (
    <ApplicantListContext.Provider value={{ applicantList, setApplicantList }}>
      {children}
    </ApplicantListContext.Provider>
  )
}

export function useListOfApplicantsContext() {
  return useContext(ApplicantListContext)
}

export { ApplicantListContext, ApplicantListContextProvider }
